import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';

export const ColumnFlex = styled(Flex)`
  flex-direction: column;
  margin-top: 65px;
  & > div:nth-child(2n + 1) > div {
    align-items: center;
  }
`;
