import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BodyLarge, BodySmall, colors, Flex, Icon, Separator } from '@beauty/beauty-market-ui';
import { Currency } from '../../../../../constants';
import { getCurrencyIcon } from '../../../../../helpers';
import { useAppSelector } from '../../../../../store/hooks';
import { selectUser } from '../../../../../store/redux-slices/userSlice';
import { EditCurrencySidebar } from '../Sidebars/EditCurrencySidebar';
import { StyledArrow, StyledIcon } from './style';

export const CurrencySection = () => {
  const { t } = useTranslation();

  const { profile } = useAppSelector(selectUser);
  const currency = profile.currency || Currency.ILS;

  const [isOpen, setOpen] = useState(false);

  const handleSubmit = () => setOpen(false);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <Flex gap="8px" alignItems="center" cursor="pointer" onClick={() => setOpen(true)} p="12px 0px 13px">
        <Icon p="10px" minWidth="20px" height="20px" stroke={colors.black.standard}>
          {getCurrencyIcon(currency)}
        </Icon>
        <Flex flexDirection="column">
          <BodySmall>{t('profile.currency.currency')}</BodySmall>
          <BodyLarge large>{currency}</BodyLarge>
        </Flex>
        <StyledIcon>
          <StyledArrow />
        </StyledIcon>
      </Flex>
      <Separator />
      <EditCurrencySidebar isOpen={isOpen} currency={currency} onSubmit={handleSubmit} onClose={handleClose} />
    </>
  );
};
