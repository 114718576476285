import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Promo, Flex } from '@beauty/beauty-market-ui';
import { ShareOrganisationModal } from '../../../../components';
import { getFullOrganisationLink } from '../../../../helpers/utils';
import { useToggle } from '../../../../hooks/useToggle';
import { FavouritesListWrapper } from '../style';
import { FavouritesOffer } from '../types';

export interface FavouritesListProps {
  favourites: FavouritesOffer[];
  setIsOpenModal: (value: boolean) => void;
}

export const FavouritesList = ({ favourites, setIsOpenModal }: FavouritesListProps) => {
  const [isShareModalOpen, setShareModalOpen] = useToggle(false);
  const [organisationId, setOrganisationId] = useState<string>('');

  const { t } = useTranslation();

  const handleOnFavouriteClick = () => {
    setIsOpenModal(true);
  };

  const handleModalOpen = (id: string) => {
    setOrganisationId(id);
    setShareModalOpen();
  };

  return (
    <FavouritesListWrapper>
      {favourites.map(offer => (
        <Flex width="334px" key={offer.id}>
          <Promo
            image={offer.image}
            headline={offer.headline}
            title={offer.title}
            rating={offer.rating}
            reviews={t('organisation.reviews')}
            reviewsCount={offer.reviewsCount}
            photos={t('organisation.photos')}
            photosCount={offer.photosCount}
            isBlank
            href={offer.id !== '0' && getFullOrganisationLink(offer.id)}
            onShareClick={() => offer.id !== '0' && handleModalOpen(offer.id)}
            onFavouriteClick={handleOnFavouriteClick}
            isActive
          />
        </Flex>
      ))}
      {isShareModalOpen && (
        <ShareOrganisationModal
          handelModalClose={setShareModalOpen}
          isShareModalOpen={isShareModalOpen}
          organisationId={organisationId}
        />
      )}
    </FavouritesListWrapper>
  );
};
