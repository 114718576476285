import { TFunction } from 'react-i18next';
import { NotifyStatus, NotifyPropsType } from '@beauty/beauty-market-ui';
import { UserActions } from '../../types/user';

const successTitles = {
  [UserActions.ChangeNotificationLanguage]: 'alerts.title.success',
  [UserActions.ChangePassword]: 'alerts.title.success',
  [UserActions.UpdateProfile]: 'profile.alerts.title.editProfileSuccess',
  [UserActions.UpdatePhone]: 'profile.alerts.title.editPhoneSuccess',
};

const errorTitles = {
  [UserActions.ChangeNotificationLanguage]: 'alerts.title.error',
  [UserActions.ChangePassword]: 'alerts.title.error',
  [UserActions.UpdateProfile]: 'profile.alerts.title.editProfileError',
  [UserActions.UpdatePhone]: 'profile.alerts.title.editPhoneError',
};

const successSubtitles = {
  [UserActions.ChangeNotificationLanguage]: 'alerts.subtitle.informationUpdated',
  [UserActions.ChangePassword]: 'alerts.subtitle.informationUpdated',
  [UserActions.UpdateProfile]: 'profile.alerts.subtitle.editProfileSuccess',
  [UserActions.UpdatePhone]: 'profile.alerts.subtitle.editPhoneSuccess',
};

const errorSubtitles = {
  [UserActions.ChangeNotificationLanguage]: 'alerts.subtitle.informationNotUpdated',
  [UserActions.ChangePassword]: 'alerts.subtitle.informationNotUpdated',
  [UserActions.UpdateProfile]: 'profile.alerts.subtitle.editProfileError',
  [UserActions.UpdatePhone]: 'profile.alerts.subtitle.editPhoneError',
};

export const getUserNotifyContent = (
  status: NotifyStatus,
  action: UserActions,
  t: TFunction<'translation', undefined>,
  message?: string,
): NotifyPropsType => {
  const title = status === NotifyStatus.SUCCESS ? successTitles[action] : errorTitles[action];
  const subtitle = status === NotifyStatus.SUCCESS ? successSubtitles[action] : errorSubtitles[action];

  return {
    id: action,
    status,
    title: t(title),
    subtitle: message || t(subtitle),
  };
};
