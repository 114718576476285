import { useTranslation } from 'react-i18next';
import { Button, CloseIcon, BottomSheet, Flex, DataList } from '@beauty/beauty-market-ui';
import { BookingPopup } from '../../../components';
import { useMediaScreen } from '../../../hooks/useMediaScreen';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/redux-slices/userSlice';

interface PopupProps {
  onClosePopup: () => void;
  onButtonClick: () => void;
  disabled?: boolean;
}

const Popup = ({ onClosePopup, onButtonClick, disabled }: PopupProps) => {
  const { booking } = useAppSelector(selectUser);
  const { t } = useTranslation();

  const { isDesktop } = useMediaScreen('md');

  const primaryButton = (
    <Button size="large" width="290px" onClick={onButtonClick}>
      {t('organisation.booking.dateOfVisit')}
    </Button>
  );

  const secondaryButton = (
    <Button design="secondary" onClick={onClosePopup}>
      <CloseIcon height="22" width="22" />
    </Button>
  );

  const content = booking.service && (
    <DataList
      title={booking.service.label}
      description={booking.service.description}
      oldPrice={booking.service.oldPrice}
      price={booking.service.price}
    />
  );

  return isDesktop ? (
    <BookingPopup
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      content={content}
      disabled={disabled}
    />
  ) : (
    <BottomSheet
      content={content}
      isOpen={onClosePopup}
      detent="content-height"
      FooterBody={
        <Flex width="100%" justifyContent="space-between">
          <Button design="secondary" onClick={onClosePopup}>
            <CloseIcon height="22" width="22" />
          </Button>
          <Button size="large" width="290px" onClick={onButtonClick}>
            {t('organisation.booking.dateOfVisit')}
          </Button>
        </Flex>
      }
      onClose={onClosePopup}
    />
  );
};

export default Popup;
