import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { H2 } from '@beauty/beauty-market-ui';
import { Container, Crumbs } from '../../../components';
import { getShownEvents } from '../../../helpers/appointments';
import { useGetAppointments } from '../../../hooks/useGetAppointments';
import { useGetSearchParams } from '../../../routes/hooks/useGetSearchParams';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAppointments, setLastAddedId } from '../../../store/redux-slices/appointmentsSlice';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { AppointmentType } from '../../../types/appointment';
import { EventInfo } from './components/EventInfo';
import { EventsCategories } from './components/EventsCategories';
import { EventsList } from './components/EventsList';
import { NoAppointment } from './components/NoAppointment';
import { confirmedList, EventStatus } from './constants';
import { BodyWrapper } from './style';
import { FullStatus } from './types';

const Appointments = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const dispatch = useAppDispatch();
  const queryParams = useGetSearchParams()[0];

  const { isForceFetch, user } = useAppSelector(selectUser);
  const { appointments: events, lastAddedId } = useAppSelector(selectAppointments);
  const { isLoading } = useGetAppointments(user.userId, isForceFetch);

  const selectedStatus = state?.selectedStatus;

  const [status, setStatus] = useState<FullStatus>(FullStatus.ALL);
  const [selectedEvent, setSelectedEvent] = useState<AppointmentType | undefined>();

  const shownEvents = useMemo(() => (events ? getShownEvents(events, status) : []), [events, status]);

  const findEvent = (event: AppointmentType) => {
    if (status === FullStatus.CONFIRMED) {
      return confirmedList.includes(event.status);
    }
    return (status as unknown as EventStatus) === event.status;
  };

  const foundedEvent = events.find(findEvent);
  const foundedStatus = foundedEvent && confirmedList.includes(foundedEvent?.status) ? FullStatus.CONFIRMED : status;
  const active = foundedEvent ? foundedStatus : FullStatus.ALL;

  const handleClick = (id: string) => {
    setSelectedEvent(shownEvents.find(event => event.id === id));
    dispatch(setLastAddedId(null));
  };

  useEffect(() => {
    !lastAddedId && setSelectedEvent(shownEvents[0]);
  }, [events, status]);

  useEffect(() => {
    lastAddedId && setSelectedEvent(shownEvents?.find(event => event.id === lastAddedId));
  }, [lastAddedId, shownEvents]);

  useEffect(() => {
    selectedStatus ? setStatus(selectedStatus) : setStatus(FullStatus.ALL);
  }, [selectedStatus]);

  useEffect(() => {
    if (queryParams?.review) {
      dispatch(setLastAddedId(queryParams.review));
      setStatus(FullStatus.PAST);
      setSelectedEvent(shownEvents.find(event => event.id === queryParams.review));
    }
  }, [queryParams]);

  return (
    <>
      <Crumbs />
      <Container flexDirection="column" mt={['24px', '24px', '24px', 0]} mb={['80px', '80px', '80px', 0]}>
        <H2 mb={events ? '40px' : '0px'}>{t('appointments.appointments')}</H2>
        {!events?.length ? (
          <NoAppointment />
        ) : (
          <>
            <EventsCategories
              events={events}
              active={active}
              onChangeStatus={(eventStatus: FullStatus) => {
                setStatus(eventStatus);
                dispatch(setLastAddedId(null));
              }}
            />
            <BodyWrapper>
              <EventsList
                events={shownEvents}
                selected={selectedEvent}
                onButtonClick={(id: string) => handleClick(id)}
              />
              {selectedEvent && (
                <EventInfo event={selectedEvent} toReview={!!queryParams?.review && !selectedEvent.isReviewed} />
              )}
            </BodyWrapper>
          </>
        )}
      </Container>
    </>
  );
};

export default Appointments;
