import styled from 'styled-components';
import { Flex, Link, BodyLarge, mediaQueries } from '@beauty/beauty-market-ui';

export const StyledButton = styled(Flex)`
  gap: 16px;
  flex-direction: column;
  ${mediaQueries.md} {
    flex-direction: row;
    justify-content: flex-end;
    ${({ theme }) => `margin-${theme.rtl ? 'right' : 'left'}: auto`};
    button {
      min-width: 240px;
    }
  }
`;

export const LinkStyled = styled(Link)`
  display: inline !important;
`;

export const BodyLargeStyled = styled(BodyLarge)`
  display: inline !important;
`;
