import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { has } from 'lodash';
import { ResponseOrganisation } from '../../page/Organisation/types';
import { ServiceType } from '../../types';
import { OrgSpecialistType, SpecialistType } from '../../types/specialist';
import { RootState } from '../store';

export interface OrganisationsState {
  organisation: ResponseOrganisation | null;
  selectedService: ServiceType | null;
  isShowSearch: boolean;
  isMap: boolean;
}

const initialState: OrganisationsState = {
  organisation: null,
  selectedService: null,
  isShowSearch: true,
  isMap: false,
};

export const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    setOrganisation: (state, action: PayloadAction<{ organisation: any }>) => {
      state.organisation = action.payload.organisation;
    },
    removeOrganisation: () => initialState,
    setSpecialists: (state, action: PayloadAction<{ specialists: OrgSpecialistType[] }>) => {
      state.organisation!.orgSpecialist = action.payload.specialists;
    },
    addSpecialist: (state, action: PayloadAction<{ specialist: SpecialistType }>) => {
      if (has(state.organisation, 'team'))
        !state.organisation!.team.find(member => member.id === action.payload.specialist.id) &&
          state.organisation!.team.push({ ...action.payload.specialist, id: action.payload.specialist.id });
    },
    setSelectedService: (state, action: PayloadAction<ServiceType>) => {
      state.selectedService = action.payload;
    },
    setShowSearch: (state, action: PayloadAction<boolean>) => {
      state.isShowSearch = action.payload;
    },
    setMap: (state, action: PayloadAction<boolean>) => {
      state.isMap = action.payload;
    },
  },
});

export const {
  setOrganisation,
  removeOrganisation,
  setSpecialists,
  addSpecialist,
  setSelectedService,
  setShowSearch,
  setMap,
} = organisationSlice.actions;

export const selectOrganisation = (state: RootState) => state.organisation;

export default organisationSlice.reducer;
