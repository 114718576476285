import { useEffect, useState } from 'react';
import { theme as defaultTheme, Theme } from '@beauty/beauty-market-ui';
import { isRtl } from '../helpers/rtl';

export const useTheme = () => {
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  const rtl = isRtl();

  useEffect(() => {
    document.dir = rtl ? 'rtl' : 'ltr';
    setTheme({ ...theme, rtl });
  }, [rtl]);

  return theme;
};
