import { useEffect, useState } from 'react';
import { Nullable } from 'tsdef';
import { getNotifications } from '../helpers/notifications';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectUser, setNotifications } from '../store/redux-slices/userSlice';
import { Notification, RenderNotificationsType } from '../types/notifications';
import { mapNotification } from './helpers';

export const useGetNotifications = (userId: string, isFetch?: boolean) => {
  const [isLoading, setIsLoading] = useState(true);
  const [nots, setNots] = useState<Nullable<RenderNotificationsType>>(null);
  const dispatch = useAppDispatch();
  const { activeNotificationId } = useAppSelector(selectUser);

  useEffect(() => {
    let isNotificationsLoading = true;

    const fetchNotifications = async () => {
      setIsLoading(true);
      const responseNew = await getNotifications(userId);
      const responseArchive = await getNotifications(userId, true);

      if (isNotificationsLoading) {
        if (responseNew?.status === 200 && responseArchive?.status === 200) {
          const newNotifications: Notification[] = responseNew.data?.map(mapNotification);

          const archiveNotifications: Notification[] = responseArchive.data?.map(mapNotification);

          setNots({ new: newNotifications, archive: archiveNotifications });
          dispatch(setNotifications({ new: newNotifications, archive: archiveNotifications }));
          setIsLoading(false);
        }
      }
    };

    (!activeNotificationId || isFetch) && !!userId && fetchNotifications();

    return () => {
      isNotificationsLoading = false;
    };
  }, [userId, activeNotificationId, isFetch]);

  return {
    isLoading,
    nots,
  };
};
