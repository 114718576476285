import { Form, Formik } from 'formik';
import { isNil, omitBy } from 'lodash';
import { Button } from '@beauty/beauty-market-ui';
import { apiRequest } from '../api/api';
import { AuthEndpoints } from '../api/endpoints/endpoints';
import { FormikInput } from '../components/functional/formik/formik-input/FormikInput';
import { SMS_CODE_EXPIRATION_TIME } from '../constants';
import { LoginFormSmsCodeFields } from '../page/Login/Login.definitions';
import { SmsFormWrapper } from '../page/Login/style';
import { CreateAccountType } from '../types/user';

export const registration = async (data: CreateAccountType) => {
  const [error, response] = await apiRequest.patch({
    endpoint: AuthEndpoints.Root,
    data: { ...omitBy({ ...data }, isNil), email: data.email.toLowerCase() },
  });
  return response ? response.data : error?.data;
};

export const getSecondsLeft = (creationTime: number) => {
  const nowTime = new Date().getTime();
  const diffSeconds = Math.floor((nowTime - creationTime) / 1000) + 1;
  return diffSeconds > SMS_CODE_EXPIRATION_TIME ? 0 : SMS_CODE_EXPIRATION_TIME - diffSeconds;
};

export const smsForm = (formikContextValue, invalidType, setInvalidType, isDefaultValue, t) => (
  <SmsFormWrapper>
    <Formik {...formikContextValue}>
      {({ isValid }) => (
        <Form>
          <FormikInput
            autoFocus
            width="160px"
            id={LoginFormSmsCodeFields.SMSCode}
            name={LoginFormSmsCodeFields.SMSCode}
            placeholder={t('registration.smsCode')}
            invalidType={invalidType}
            onInput={() => setInvalidType(null)}
            // defaultValue={isDefaultValue && ''}
          />

          <Button disabled={!isValid || invalidType} design="primary" mt="16px" width="100%" size="large" type="submit">
            {t(`login.continue`)}
          </Button>
        </Form>
      )}
    </Formik>
  </SmsFormWrapper>
);
