import { TFunction } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import useSetCrumbsQuery from '../routes/hooks/useSetCrumbsQuery';
import { RouterUrl } from '../routes/routes';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectHistory, GoBack } from '../store/redux-slices/historySlice';
import { TimeslotsType } from '../types';
import { NotificationStatus, MapNotification } from '../types/notifications';
import { TimeslotsRequest } from '../types/timeslot';
import { crumbsPages } from './constants';

// TimeslotId is stored in a Redux store and used to represent 'start' field to book an appointment
const createTimeslotId = (timeslotDate: string, startTime?: string) =>
  moment(`${timeslotDate} ${startTime}`, 'YYYY-MM-DD HH:mm').utcOffset(0).format('YYYY-MM-DD HH:mm').replace(' ', ':');

const getCrumb = (t: TFunction<'translation'>, name: string, link: string) => {
  const navigate = useNavigate();
  return [
    {
      name: `${t(`breadcrumbs.back`)} ${name}`,
      onItemClick: () => navigate(link),
    },
    {
      name,
      onItemClick: () => navigate(link),
    },
  ];
};

const getNameBtPathmame = (pathname: string) => {
  switch (pathname) {
    case '/about-us':
      return 'aboutUs';
    case '/client/profile/':
      return 'profile';
    case '/client/appointments/':
      return 'appointments';
    case '/client/favourites/':
      return 'favourites';
    case '/faq':
      return 'faq';
    default:
      return '';
  }
};

export const getPathWithoutId = (path: string) => {
  const lastIndex = path.lastIndexOf('/');
  return `${path.substring(0, lastIndex)}/`;
};

export const getCrumbsByHistory = (t: TFunction<'translation'>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { paths, organisation, topCategory, from } = useAppSelector(selectHistory).history;
  const prevLink = (paths && paths[paths.length - 2]) || '';
  const prevPath = getPathWithoutId(prevLink);
  const currentPath = getPathWithoutId(location.pathname);
  const dispatch = useAppDispatch();
  const crumbsQuery = useSetCrumbsQuery(organisation.name);

  const ser = {
    name: topCategory === 'All' ? t('breadcrumbs.list') : topCategory,
    onItemClick: () => navigate(RouterUrl.AllOffers),
  };

  const bookingEmpty = {
    name: t(`breadcrumbs.booking`),
    onItemClick: () => console.log('bookingEmpty onItemClick'),
  };

  const orgEmpty = { name: organisation.name, onItemClick: () => console.log('orgEmpty onItemClick') };

  const [backToOrg, org] = getCrumb(t, organisation.name, organisation.path + crumbsQuery);
  const backToHome = {
    name: t('breadcrumbs.backToHome'),
    onItemClick: () => navigate(RouterUrl.Homepage),
  };

  const home = {
    name: t('breadcrumbs.home'),
    onItemClick: () => navigate(RouterUrl.Homepage),
  };

  const backToOffers = {
    name: `${t('breadcrumbs.back')} ${t(`breadcrumbs.list`)}`,
    onItemClick: () => navigate(RouterUrl.AllOffers + crumbsQuery),
  };

  const backToBooking = {
    name: `${t('breadcrumbs.back')} ${t('breadcrumbs.booking')}`,
    onItemClick: () => navigate(`${RouterUrl.Booking}/${organisation.path.split('/').pop()}${crumbsQuery}`),
  };

  const back = {
    name: `${t('breadcrumbs.back')} ${t(`breadcrumbs.${getNameBtPathmame(prevPath)}`)}`,
    onItemClick: () => {
      navigate(prevLink);
      dispatch(GoBack());
    },
  };

  // TODO Review and refactor
  if (crumbsPages.includes(prevPath)) {
    switch (true) {
      case currentPath === `${RouterUrl.Organisation}/`:
        return from === 'Home' ? [home, orgEmpty] : [home, ser, orgEmpty];
      case currentPath === `${RouterUrl.Booking}/`:
        return from === 'Home' ? [home, org, bookingEmpty] : [home, ser, org, bookingEmpty];
      case prevPath === RouterUrl.AllOffers:
        return [backToOffers];
      case prevPath === `${RouterUrl.Booking}/`:
        return [backToBooking];
      case prevPath === `${RouterUrl.Organisation}/`:
        return [backToOrg];
      case prevPath === RouterUrl.Homepage:
        return [backToHome];
      default:
        return prevLink && prevPath !== currentPath ? [back] : [backToHome];
    }
  }
  return [backToHome];
};

export const getCrumbsByPath = (t: TFunction<'translation'>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgId } = useParams();
  return location.pathname
    .split('/')
    .filter(crumb => crumb !== '')
    .reduce(
      (acc, crumb, index, crumbs) => {
        if (index < crumbs.length - (orgId ? 2 : 1)) {
          const navigateParam = orgId && index === 0 ? orgId : '';
          const currentLink = `${index === 0 ? '' : '/'}${crumbs.slice(0, index).join('/')}/${crumb}/`;
          acc.push({
            name: t(`breadcrumbs.${crumb}`),
            onItemClick: () => navigate(`${currentLink}${navigateParam}`),
          });
        }
        return acc;
      },
      [{ name: t('breadcrumbs.home'), onItemClick: () => navigate(RouterUrl.Homepage) }],
    );
};

export const modifyTimeslotsWithId: (timeslots: TimeslotsRequest, slots?: TimeslotsType) => TimeslotsType = (
  timeslots,
  slots,
) => {
  const result = { ...slots };
  timeslots?.map(item => {
    result[item.date] =
      item.timeslots?.map(timeslot => ({
        ...timeslot,
        id: createTimeslotId(item.date, timeslot.start),
      })) || null;
    return null;
  });
  return result;
};

export const mapNotification: MapNotification = notification => {
  const {
    id,
    appointmentId,
    type,
    createdAt: time,
    status,
    specialist,
    specAvatar,
    specialization,
    organization,
    orgAvatar,
    address,
    isReviewed,
  } = notification;
  return {
    id,
    appointmentId,
    type,
    time,
    status,
    isRead: status === NotificationStatus.VIEWED,
    specialist,
    specAvatar,
    specialization,
    organization,
    orgAvatar,
    fullAddress: address?.fullAddress,
    isReviewed,
  };
};
