import { useEffect, useRef } from 'react';

export const useFocus = () => {
  const ref = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const handleFocus = () => ref.current?.focus();
    document.addEventListener('load', handleFocus);
    return () => document.removeEventListener('load', handleFocus);
  }, [ref.current]);

  return ref;
};
