import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';

export const PhoneWrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  gap: 8px;
  flex-direction: ${({ theme }) => (theme.rtl ? 'row-reverse' : 'row')};
  & > div:last-child {
    width: 100%;
  }
`;
