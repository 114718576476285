import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { ListRegionCode } from '@beauty/beauty-market-ui';
import { regionListCode } from '../../../../constants';
import { FormikInput } from '../formik-input/FormikInput';
import { PhoneWrapper } from './style';

type Props = {
  codeId: string;
  phoneId: string;
  codeName?: string;
  phoneName?: string;
  placeholder: string;
  disabled?: boolean;
  required?: boolean;
  caption?: ReactNode;
  captionIcon?: ReactNode;
  iconRight?: ReactNode;
  autoFocus?: ReactNode;
  design?: 'white' | 'grey';
};

const FormikPhone = ({
  codeId,
  phoneId,
  codeName = '',
  phoneName = '',
  placeholder = '',
  disabled = false,
  required = false,
  caption = null,
  captionIcon = null,
  iconRight = null,
  autoFocus = false,
  design = 'white',
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { setFieldValue, values, touched, errors } = useFormikContext();

  return (
    <PhoneWrapper {...rest}>
      <ListRegionCode
        design={design}
        backgroudColor={design}
        id={codeId}
        name={codeName || codeId}
        options={regionListCode}
        currentRegionCode={values && codeId ? values[codeId] : ''}
        handleSelect={async (code: string) => setFieldValue(codeId, code)}
        placeholder={t('profile.region')}
        disabled={disabled}
        required={required}
      />
      <FormikInput
        design={design}
        id={phoneId}
        name={phoneName || phoneId}
        invalidType={touched[phoneId] && errors[phoneId]}
        placeholder={placeholder || t('profile.phone')}
        disabled={disabled}
        captionIcon={captionIcon}
        caption={touched[phoneId] && !!errors[phoneId] ? errors[phoneId] : caption}
        iconRight={iconRight}
        autoFocus={autoFocus}
        type="tel"
        autoComplete="tel"
        required={required}
      />
    </PhoneWrapper>
  );
};

export { FormikPhone };
