import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { H5, Slider, PhotoViewer } from '@beauty/beauty-market-ui';
import { isRtl } from '../../../helpers/rtl';
import { useMediaScreen } from '../../../hooks';
import { useFullImage } from '../../../hooks/useFullImage';
import { GalleryImage } from '../style';

interface PhotoGalleryProps {
  photos: string[];
}

const getImages: (slides: string[], setIndex: (index: number) => void) => JSX.Element[] = (slides, setIndex) =>
  slides.map((slide, index) => <GalleryImage src={slide} key={slide} alt="slide" onClick={() => setIndex(index)} />);

const PhotoGallery = ({ photos }: PhotoGalleryProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');
  const { isOpen, index, setIndex, onClose, onPrevArrowClick, onNextArrowClick } = useFullImage(photos.length);
  const rtl = isRtl();

  const title = <H5>{t('organisation.about.photoGallery')}</H5>;
  const slides = useMemo(() => getImages(photos, setIndex), [photos]);

  return (
    <>
      <Slider
        title={title}
        titleBottomMargin="24px"
        slides={slides}
        marginTop={isDesktop ? '40px' : '32px'}
        isPaginationEnabled
        rtl={rtl}
      />
      <PhotoViewer
        isOpen={isOpen}
        photo={photos[index]}
        onPrevArrowClick={onPrevArrowClick}
        onNextArrowClick={onNextArrowClick}
        onClose={onClose}
      />
    </>
  );
};

export default PhotoGallery;
