import { useTranslation } from 'react-i18next';
import { BodyLarge, H3, Button, Flex } from '@beauty/beauty-market-ui';

type LogoutAccountProps = {
  onLogoutClick: () => void;
};

export const LogoutAccount = ({ onLogoutClick }: LogoutAccountProps) => {
  const { t } = useTranslation();

  return (
    <Flex gap="16px" flexDirection="column" mb="60px">
      <H3>{t('profile.logout')}</H3>
      <BodyLarge large>{t('profile.areYouSureLogout')}</BodyLarge>
      <Button design="quaternary" onClick={onLogoutClick}>
        {t('profile.logoutButton')}
      </Button>
    </Flex>
  );
};
