import styled, { css } from 'styled-components';
import { Div, Flex, Link, Separator, colors } from '@beauty/beauty-market-ui';
import { zIndex } from '../../constants';

export const FooterWrapper = styled(Div)<{ isHigher: boolean }>`
  position: relative;
  grid-area: footer;
  ${({ isHigher }) =>
    isHigher
      ? css`
          padding-bottom: 84px;
        `
      : css`
          padding-bottom: 0;
        `}
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.white.standard};

  ${({ theme }) => `${theme.mediaQueries.md} {
    max-width: 1060px;
    margin: auto;
    padding-bottom: 84px;
    background-color: transparent;
}`}
`;

export const FooterContainer = styled(Flex)`
  position: relative;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: ${zIndex.footerContainer};
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;

  ${({ theme }) => css`
    ${theme.mediaQueries.md} {
      position: fixed;
      bottom: 0;
      left: 0;
      justify-content: center;
      backdrop-filter: blur(12px);
    }
  `};

  @media screen and (max-width: 1060px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const BottomFooterWrapper = styled(Flex)`
  width: 100%;

  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;

  @media (max-width: 375px) {
    align-items: start;
  }

  ${({ theme }) => css`
    ${theme.mediaQueries.md} {
      max-width: 1060px;
    }
  `}
`;

export const MixFlex = styled(Flex)`
  gap: 20px;
  @media (max-width: 375px) {
    flex-direction: column-reverse;
    gap: 15px;
  }
`;

export const StyledLink = styled(Link)`
  @media (max-width: 375px) {
    margin-bottom: 16px !important;
  }
`;

export const StyledSeparator = styled(Separator)`
  ${({ theme }) => css`
    ${theme.mediaQueries.md} {
      width: 100%;
      margin-top: -80px !important;
    }
  `}
`;
