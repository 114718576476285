import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { union } from 'lodash';
import { Flex } from '@beauty/beauty-market-ui';
import { FilterType, KM_IN_DEGREES, SHOW_RADIUS, telAvivGeolocation, zIndex } from '../constants';
import { fetchFoundOrganisations } from '../helpers/search';
import { ServiceSearch } from '../page/HomePage/components/Search';
import { RouterUrl } from '../routes/routes';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectAppliedFiltersCount, selectOffersFilter } from '../store/redux-slices/offersFilterSlice';
import {
  searchState,
  setBounds,
  setCurrentLocationBounds,
  setFilterType,
  setGeolocation,
  setIsSearchStart,
  setOrganisations,
  setSearchValues,
  updateLocationValue,
} from '../store/redux-slices/searchSlice';
import { FoundResponse } from '../types/general';

export const Search = forwardRef((_, ref) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { location, service, searchValues, isCurrentLocation, bounds } = useAppSelector(searchState);
  const { looking, priceRange, distanceRange, language } = useAppSelector(selectOffersFilter);
  const selectedFiltersCount = useAppSelector(selectAppliedFiltersCount);

  const translationProps = {
    regionButtonCaption: t('currentLocation'),
    placeholder: t('search.placeholder'),
    topHint: t('search.topHint'),
    bottomHint: t('search.bottomHint'),
    seeMore: t('search.showMore'),
    hide: t('search.showLess'),
  };

  const handleLocation = (newLocation: string) => {
    dispatch(updateLocationValue({ location: newLocation }));
  };

  const updateState = (organisations: FoundResponse[], dropdownValues: string[]) => {
    dispatch(setFilterType(FilterType.SEARCH));
    dispatch(setOrganisations(organisations));
    dispatch(setSearchValues({ values: dropdownValues }));
    dispatch(setIsSearchStart(true));
  };

  // search orgs by search input value
  const handleService = async (toFind: string, mapBounds?: any) => {
    const encodedToFind = toFind.replace(/[+]/g, encodeURIComponent('+'));
    const response: FoundResponse[] = await fetchFoundOrganisations(
      !!selectedFiltersCount,
      encodeURIComponent(encodedToFind),
      mapBounds || bounds,
      priceRange.min,
      priceRange.max,
      distanceRange.min,
      distanceRange.max,
      looking.value.filter(item => item !== 'SHABBAT').join(','),
      language.value.join(','),
      !!looking.value.find(item => item === 'SHABBAT'),
    );
    const searchOptions = union(
      response.reduce((sumOptions: string[], currentOrganisation) => [...sumOptions, ...currentOrganisation.field], []),
    );
    updateState(response, searchOptions);
  };

  const handleClick = () => {
    navigate(RouterUrl.AllOffers, {
      state: {
        view: 'map',
      },
    });
  };

  return (
    <Flex ref={ref} width="100%" justifyContent="center" position="relative" zIndex={zIndex.search}>
      <ServiceSearch
        {...translationProps}
        list={searchValues}
        location={t(!isCurrentLocation ? location : 'currentLocation')}
        inputValue={service}
        RegionButtonCaption={t('currentLocation')}
        onSelect={() => console.log('onSelect')}
        onGetLocation={handleLocation}
        onGetService={handleService}
        onClickService={() => {
          handleClick();
        }}
        onEnterService={handleClick}
        onClickLocation={(checkedLocation: string) => {
          handleLocation(checkedLocation);
          handleClick();
        }}
        onGetCurrentLocation={(toFind: string) => {
          navigator.geolocation.getCurrentPosition(
            async position => {
              const { latitude, longitude } = position.coords;
              dispatch(
                setCurrentLocationBounds({
                  bounds: {
                    NE: { lat: latitude + 0.01 * SHOW_RADIUS, lng: longitude + 0.01 * SHOW_RADIUS },
                    SW: { lat: latitude - 0.01 * SHOW_RADIUS, lng: longitude - 0.01 * SHOW_RADIUS },
                  },
                }),
              );
              dispatch(
                setBounds({
                  bounds: {
                    NE: { lat: latitude + KM_IN_DEGREES * SHOW_RADIUS, lng: longitude + KM_IN_DEGREES * SHOW_RADIUS },
                    SW: { lat: latitude - KM_IN_DEGREES * SHOW_RADIUS, lng: longitude - KM_IN_DEGREES * SHOW_RADIUS },
                  },
                  geolocation: { lat: latitude, lng: longitude },
                }),
              );
              if (toFind) {
                const response: FoundResponse[] = await fetchFoundOrganisations(
                  !!selectedFiltersCount,
                  toFind,
                  {
                    NE: { lat: latitude + 0.01 * SHOW_RADIUS, lng: longitude + 0.01 * SHOW_RADIUS },
                    SW: { lat: latitude - 0.01 * SHOW_RADIUS, lng: longitude - 0.01 * SHOW_RADIUS },
                  },
                  priceRange.min,
                  priceRange.max,
                  distanceRange.min,
                  distanceRange.max,
                  looking.value.filter(item => item !== 'SHABBAT').join(','),
                  language.value.join(','),
                  !!looking.value.find(item => item === 'SHABBAT'),
                );
                const searchOptions = union(
                  response.reduce(
                    (sumOptions: string[], currentOrganisation) => [...sumOptions, ...currentOrganisation.field],
                    [],
                  ),
                );
                updateState(response, searchOptions);
              }
            },
            error => {
              dispatch(setGeolocation(telAvivGeolocation));
            },
          );
        }}
      />
    </Flex>
  );
});

Search.displayName = 'Search';
