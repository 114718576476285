export interface FavouritesOffer {
  id: string;
  image: string;
  headline: string;
  title: string;
  rating: string;
  reviewsCount: string;
  photosCount: string;
}

export interface FavouritesSlideType {
  id: string;
  name: string;
  count: number;
}

export enum FavouritesCategories {
  Nails = 'Nails',
  Massage = 'Massage',
  Hairstyle = 'Hairstyle',
  MakeUp = 'Make up',
  Blabla = 'Bla bla',
  ShowAll = 'all',
}
