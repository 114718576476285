import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, H2 } from '@beauty/beauty-market-ui';
import { AnchorMenu } from '../../components/AnchorMenu/AnchorMenu';
import { PrivacyWrapper, StyledCaption, StyledContainer } from '../../style';
import { useScrollY } from '../HomePage/hooks/useScrollPosition';
import { privacySectionsCount, privacyTitles } from './constants';
import { PrivacyItem } from './PrivacyItem';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const [activeSection, setActiveSection] = useState(-1);
  const [isClicked, setIsClicked] = useState(false);

  const titleRefs = useRef<Flex[]>(new Array(privacyTitles.length));

  const getArticles = (sectionTitle: string, sectionNumber: number): string[] =>
    new Array(privacySectionsCount[sectionNumber]).fill(0).reduce((articles: string[], item, articleNumber) => {
      articles.push(t(`privacy.${sectionTitle}.article${articleNumber + 1}`));
      return articles;
    }, []);

  const posY = useScrollY();

  useEffect(() => {
    const currentSection =
      privacyTitles.length - titleRefs.current.reverse().findIndex(ref => ref.getBoundingClientRect().y < 0) - 1;
    titleRefs.current.reverse();

    if (!isClicked) {
      setActiveSection(currentSection);
    }
  }, [posY, privacyTitles]);

  return (
    <StyledContainer>
      <PrivacyWrapper>
        <AnchorMenu
          menuItems={privacyTitles.map(title => t(`privacy.${title}.title`))}
          hrefs={titleRefs}
          activeSection={activeSection}
          onClick={setIsClicked}
        />

        <Flex width="100%" flexDirection="column">
          <H2 mb="8px">{t('privacy.title')}</H2>
          <StyledCaption>{t('privacy.lastUpdated')}</StyledCaption>
          <Flex flexDirection="column" maxWidth="620px">
            {privacyTitles.map((title, i) => (
              <PrivacyItem
                key={title}
                id={privacyTitles[i]}
                title={t(`privacy.${title}.title`)}
                infos={getArticles(title, i)}
                ref={el => {
                  titleRefs.current[i] = el;
                }}
              />
            ))}
          </Flex>
        </Flex>
      </PrivacyWrapper>
    </StyledContainer>
  );
};
