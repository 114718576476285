import { t } from 'i18next';
import { RouterUrl } from '../../routes/routes';

export const menuItems = id => [
  {
    disabled: false,
    item: t('menu.profile'),
    link: `${RouterUrl.ClientProfile}/${id}`,
  },
  {
    disabled: false,
    item: t('menu.appointments'),
    link: `${RouterUrl.ClientAppointments}/${id}`,
  },
  {
    disabled: false,
    item: t('menu.favourites'),
    link: `${RouterUrl.ClientFavourites}/${id}`,
  },
  // {
  //   disabled: false,
  //   item: 'Docs',
  //   link: RouterUrl.ClientDocs + '/'  + id,
  // },
  {
    disabled: false,
    item: t('menu.faq'),
    link: RouterUrl.FAQ,
  },
];
