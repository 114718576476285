import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import { Button, Flex, Popup, Separator, BottomSheet } from '@beauty/beauty-market-ui';
import { useMediaScreen } from '../../../../hooks';
import { RouterUrl } from '../../../../routes/routes';
import {
  BodyButton,
  bottomSheetDescriptor,
  bottomSheetLabel,
  EventStatus,
  popupBody,
  popupInfo,
  popupTitle,
  SendEmailInput,
} from '../constants';
import { StyledBottomSheet } from '../style';
import { PopupBlockProps } from '../types';
import { sendEmailValidationSchema, initialSendEmailValues } from './Appointments.definitions';

const PopupBlock = ({
  event,
  whatToDo,
  isSendPopup,
  setIsPopup,
  setIsSendPopup,
  handleCancelAppointment,
  handleRebookAppointment,
  handleRescheduleAppointment,
  handleAddToCalendar,
  handleWriteReview,
  handleSendReceipt,
}: PopupBlockProps) => {
  const { t } = useTranslation();
  const [isSubmitButtonActive, setSubmitButtonActive] = useState(false);
  const navigate = useNavigate();

  const { isDesktop } = useMediaScreen('md');

  const popupCancelFooter = (
    <Flex flexDirection="column" width="100%">
      <Separator mb="16px" />
      <Flex gap="16px">
        <Button size="large" width="100%" design="secondary" onClick={() => setIsPopup(false)}>
          {t(`appointments.${popupBody.cancel.leftButton}`)}
        </Button>
        <Button
          size="large"
          width="100%"
          onClick={() => {
            setIsPopup(false);
            whatToDo === 'send' && handleSendReceipt();
            whatToDo === 'cancel' && handleCancelAppointment();
          }}
        >
          {t(`appointments.${popupBody.cancel.rightButton}`)}
        </Button>
      </Flex>
    </Flex>
  );

  const popupManageFooter = useMemo(() => {
    switch (event.status) {
      case EventStatus.PAST:
      case EventStatus.UNCLOSED:
        return (
          <Flex flexDirection="column" alignItems="center" width="100%">
            <BodyButton
              onClick={() => {
                setIsPopup(false);
                handleRebookAppointment();
              }}
              label={t(`appointments.${popupBody.manage.past.topButton}`)}
            />

            {!event.isReviewed && (
              <BodyButton
                disabled={false}
                onClick={() => {
                  setIsPopup(false);
                  handleWriteReview();
                }}
                label={t(`appointments.${popupBody.manage.past.middleButton}`)}
              />
            )}

            {/* <BodyButton
                onClick={() => {
                  setIsPopup(false);
                  setIsSendPopup(true);
                }}
                label={t(`appointments.${popupBody.manage.past.bottomButton}`)}
              /> */}
          </Flex>
        );
      case EventStatus.CONFIRMED:
      case EventStatus.PENDING:
        return (
          <Flex flexDirection="column" alignItems="center" width="100%">
            <BodyButton
              onClick={() => {
                setIsPopup(false);
                handleRescheduleAppointment();
              }}
              label={t(`appointments.${popupBody.manage.confirmed.topButton}`)}
            />

            <BodyButton
              onClick={() => {
                setIsPopup(false);
                handleAddToCalendar();
              }}
              label={t(`appointments.${popupBody.manage.confirmed.bottomButton}`)}
            />
          </Flex>
        );
      default:
        return (
          <Flex flexDirection="column" alignItems="center" width="100%">
            <BodyButton
              onClick={() => {
                setIsPopup(false);
              }}
              label={t(`appointments.${popupBody.manage.cancelled.topButton}`)}
            />
          </Flex>
        );
    }
  }, [event.status, event.orgService.organization.id]); // TODO replace 2nd in array with reviewId

  const SendPopupFooterBody = (
    <Flex gap="16px" width="100%">
      <Button size="large" width="50%" design="secondary" px="18px" onClick={() => setIsSendPopup(false)}>
        {t(`appointments.downloadReceipt`)}
      </Button>
      <Button
        size="large"
        width="50%"
        px="18px"
        type="submit"
        disabled={!isSubmitButtonActive}
        onClick={() => {
          setIsSendPopup(false);
          handleSendReceipt();
        }}
      >
        {t(`appointments.sendViaEmail`)}
      </Button>
    </Flex>
  );

  const footerBody = useMemo(
    () => (whatToDo === 'cancel' || whatToDo === 'send' ? popupCancelFooter : popupManageFooter),
    [whatToDo, event],
  );

  const formikContextValue = {
    initialValues: initialSendEmailValues,
    validationSchema: sendEmailValidationSchema,
    onSubmit: () => {
      alert('submit');
      navigate(RouterUrl.AllOffers);
    },
    validateOnMount: true,
  };

  return (
    <>
      {isDesktop ? (
        <Popup
          open
          title={popupTitle(whatToDo)}
          info={popupInfo(whatToDo)}
          FooterBody={footerBody}
          handleClose={() => setIsPopup(false)}
        />
      ) : (
        <StyledBottomSheet
          label={bottomSheetLabel(whatToDo)}
          descriptor={bottomSheetDescriptor(whatToDo)}
          isOpen
          isCancel={whatToDo !== 'manage'}
          handleClose={() => setIsPopup(false)}
          onClose={() => setIsPopup(false)}
          detent="content-height"
          content={whatToDo === 'manage' ? popupManageFooter : popupCancelFooter}
        />
      )}
      {isSendPopup && (
        <Formik {...formikContextValue}>
          {({ isValid, initialValues, values }) => {
            isValid && setSubmitButtonActive(isValid && !isEqual(initialValues, values));
            return (
              <Form>
                {isDesktop ? (
                  <Popup
                    open
                    title={t('appointments.sendReceipt')}
                    info=""
                    FooterBody={SendPopupFooterBody}
                    handleClose={() => setIsSendPopup(false)}
                  >
                    {SendEmailInput()}
                  </Popup>
                ) : (
                  <BottomSheet
                    label={t('appointments.sendReceipt')}
                    isOpen
                    handleClose={() => setIsSendPopup(false)}
                    onClose={() => setIsSendPopup(false)}
                    detent="content-height"
                    content={SendEmailInput()}
                    FooterBody={SendPopupFooterBody}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export { PopupBlock };
