import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Crumbs } from '../../components';
import { clearBooking } from '../../constants';
import { useGetOrganisation } from '../../hooks/useGetOrganisation';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectUser, updateBooking, updateUser } from '../../store/redux-slices/userSlice';
import { Popup, SelectPanel, StatePanel } from './components';
import CheckoutSidebar from './components/CheckoutSidebar';
import { BookingWrapper } from './style';

type OrganisationParams = {
  orgId: string;
};

const Booking = () => {
  const { booking } = useAppSelector(selectUser);
  const user = useAppSelector(selectUser);
  const { state: locationState } = useLocation();
  const timeslotId = locationState?.timeslotId || '';
  const shouldOpenSidebar = locationState?.shouldOpenSidebar || false;

  const { orgId } = useParams() as OrganisationParams;
  const { organisation } = useGetOrganisation(orgId, true);

  const [showTimeslots, setShowTimeslots] = useState<boolean>(!!booking.service);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(shouldOpenSidebar);
  const [isAlertVisible, setAlertVisible] = useState(false);

  const dispatch = useAppDispatch();

  const handlePrimaryBtnClick = useCallback(() => {
    if (!showTimeslots) {
      setShowTimeslots(prev => !prev);
    }
    if (showTimeslots && booking.service && booking.timeslot) {
      setAlertVisible(false);
      setIsSidebarOpen(true);
    }
  }, [showTimeslots, booking]);

  const handleSecondaryBtnClick = useCallback(() => {
    if (showTimeslots && booking.service) {
      setShowTimeslots(prev => !prev);
    } else {
      dispatch(updateBooking(clearBooking));
      dispatch(updateUser({ ...user, selectedAppointmentId: undefined }));
    }
  }, [showTimeslots, booking, dispatch]);

  useEffect(() => {
    isAlertVisible && setTimeout(() => setAlertVisible(false), 5100);
  }, [isAlertVisible]);

  useEffect(() => {
    !shouldOpenSidebar && dispatch(updateBooking({ timeslot: null }));
  }, []);

  return organisation ? (
    <>
      <Crumbs fixed />
      <Container mt={['24px', '24px', '24px', 0]} mb={['72px', '72px', '72px', 0]}>
        <BookingWrapper>
          <StatePanel organisation={organisation} />
          <SelectPanel
            showTimeslots={showTimeslots}
            offers={organisation.offers}
            orgTimezone={organisation.timezone}
            organisationName={organisation.name}
            selectedId={timeslotId}
            isAlertVisible={isAlertVisible}
            workSchedule={organisation.workSchedule}
          />
        </BookingWrapper>
      </Container>
      {(booking.service || booking.timeslot) &&
        createPortal(
          <Popup
            showTimeslots={showTimeslots}
            onPrimaryBtnClick={handlePrimaryBtnClick}
            onSecondaryBtnClick={handleSecondaryBtnClick}
            disabled
          />,
          document.getElementById('root') as HTMLElement,
        )}
      {isSidebarOpen && <CheckoutSidebar setIsOpen={setIsSidebarOpen} organisation={organisation} />}
    </>
  ) : null;
};

export default Booking;
