import { TFunction } from 'react-i18next';
import { NavigateFunction } from 'react-router';
import { Dispatch, AnyAction } from 'redux';
import { Nullable } from 'tsdef';
import { apiRequest } from '../api/api';
import { NotificationEndpoints } from '../api/endpoints/endpoints';
import { NotificationCard } from '../page/Notifications/NotificationCard';
import { RouterUrl } from '../routes/routes';
import { setLastAddedId } from '../store/redux-slices/appointmentsSlice';
import { NotificationType, Notification, NotificationsItemResponse } from '../types/notifications';

import { getFullDate } from './appointments';

const notificationBody = (
  t: TFunction<'translation', undefined>,
  specialist: Nullable<string>,
  organization: string,
) => ({
  title: {
    [NotificationType.REVIEW.toUpperCase()]: t('notifications.titles.review'),
  },
  message: {
    [NotificationType.REVIEW.toUpperCase()]: `${t('notifications.pleaseEvaluate')}${
      specialist ? `${t('notifications.withSpecialist')} ${specialist}` : ''
    }${t('notifications.at')}${organization}. ${t('notifications.yourFeedback')}`,
  },
});

export const getNotificationCard: (
  t: TFunction<'translation', undefined>,
  navigate: NavigateFunction,
  userId: string,
  item: Notification,
  index: number,
  setRead: (index: number) => void,
  setRatingOpen: (id: Nullable<string>) => void,
  language: string,
  dispatch: Dispatch<AnyAction>,
  setNotificationsPopup?: (isPopup: boolean) => void,
) => JSX.Element = (
  t,
  navigate,
  userId,
  item,
  index,
  setRead,
  setRatingOpen,
  language,
  dispatch,
  setNotificationsPopup,
) => (
  // TODO: Modify card props when other types will be added
  <NotificationCard
    key={item.id}
    icon={item.orgAvatar}
    status={item.status}
    title={notificationBody(t, item.specialist, item.organization).title[item.type]}
    placeholder={getFullDate(item.time, t, language)}
    isRead={item.isRead}
    label={notificationBody(t, item.specialist, item.organization).message[item.type]}
    onHover={() => setRead(index)}
    onLeftButtonClick={(e: Event) => {
      e.stopPropagation();
      setNotificationsPopup && setNotificationsPopup(false);
      setRatingOpen(item.id);
    }}
    leftButton={item.type === NotificationType.REVIEW ? t('notifications.rateNow') : ''}
    onClick={() => {
      setNotificationsPopup && setNotificationsPopup(false);
      dispatch(setLastAddedId(item.appointmentId));
      navigate(`${RouterUrl.ClientAppointments}/${userId}`, { state: { selectedStatus: 'PAST' } });
    }}
    leftButtonDisabled={item.isReviewed}
  />
);

export const changeNotificationStatus = async notificationId => {
  const [, response] = await apiRequest.patch({
    endpoint: NotificationEndpoints.Change,
    endpointParams: notificationId,
    data: {},
  });
  return response?.data;
};

export const markAllAsRead = async profileId => {
  const [, response] = await apiRequest.patch({
    endpoint: NotificationEndpoints.ViewAll,
    endpointParams: profileId,
    data: {},
  });
  return response?.data;
};

export const getNotifications = async (id: string, isArchive = false) => {
  const [, response] = await apiRequest.get<NotificationsItemResponse[]>({
    endpoint: NotificationEndpoints.Notifications,
    endpointParams: { profileId: id, isArchive },
  });
  return response;
};
