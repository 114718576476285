import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isRtl } from '../helpers/rtl';
import { WorkDayType } from '../types/organisation';

const delimiter = String.fromCodePoint(8211);

export const useDroptimeProps = (workSchedule: WorkDayType[]) => {
  const { t } = useTranslation();
  const rtl = isRtl();
  const rtlSchedule = [workSchedule[workSchedule.length - 1], ...workSchedule.slice(0, -1)];
  const schedule = rtl ? rtlSchedule : workSchedule;

  const todayWeekdayIndex = rtl ? moment().day() : moment().isoWeekday() - 1;
  const { openTime, closeTime, isWeekend } = schedule[todayWeekdayIndex];
  const start = moment(openTime, 'hhmm');
  const end = moment(closeTime, 'hhmm');
  const color = moment().isBetween(start, end) ? 'green' : 'red';

  const getLabel = () => {
    if (isWeekend) return t('organisation.closeToday');
    if (moment().isBefore(start)) return `${t('organisation.opens')} ${openTime}`;
    return `${openTime} ${delimiter} ${closeTime}`;
  };

  return {
    schedule,
    color: isWeekend ? 'red' : color,
    label: getLabel(),
  };
};
