import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { Nullable } from 'tsdef';
import { AppointmentType } from '../../types';
import { RootState } from '../store';

type AppointmentsState = {
  appointments: AppointmentType[];
  lastAddedId: Nullable<string>;
};

const initialState: AppointmentsState = {
  appointments: [],
  lastAddedId: null,
};

export const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    writeAppointments: (state, action: PayloadAction<{ data: AppointmentType[] }>) => {
      state.appointments = cloneDeep(action.payload.data);
    },
    setAppointment: (state, action: PayloadAction<{ id: string; data: AppointmentType }>) => {
      state.appointments[state.appointments.findIndex(item => item.id === action.payload.id)] = action.payload.data;
    },
    rebookAppointment: (
      state,
      action: PayloadAction<{ id: string; date: string; time: string; service: string; price: string }>,
    ) => {
      let appointment = state.appointments.find(item => item.id === action.payload.id);
      appointment && (appointment = { ...appointment, ...action.payload });
    },
    resetAppointments: state => {
      state.appointments = [];
    },
    setLastAddedId: (state, action: PayloadAction<Nullable<string>>) => {
      state.lastAddedId = action.payload;
    },
  },
});

export const { setAppointment, writeAppointments, rebookAppointment, resetAppointments, setLastAddedId } =
  appointmentsSlice.actions;

export const selectAppointments = (state: RootState) => state.appointments;

export default appointmentsSlice.reducer;
