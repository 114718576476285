import styled, { css } from 'styled-components';
import Container from '../Container';
import { ContainerProps } from '../Container/types';

export const BreadcrumbsContainer = styled(Container)<ContainerProps>`
  ${({ fixed }) =>
    fixed &&
    css`
      position: sticky;
      top: 80px;
      z-index: 3;
      width: 100%;
      ${({ theme }) => `padding-${theme.rtl ? 'right' : 'left'}: calc(0.5 * (100vw - 1060px))`};
      background-color: white;
    `}

  @media (max-width: 991px) {
    display: none !important;
  }
`;
