import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { Button, Flex, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { FormikDropdown } from '../../../../../components/functional/formik/formik-dropdown/FormikDropdown';
import { FormikTextfield } from '../../../../../components/functional/formik/formik-textfield/FormikTextfield';
import { deleteProfile, cleanUpAfterLogout } from '../../../../../helpers/profile';
import { RouterUrl } from '../../../../../routes/routes';
import { useAppSelector } from '../../../../../store/hooks';
import { selectUser } from '../../../../../store/redux-slices/userSlice';
import { reasonsForDelete } from '../../constants';
import {
  DeleteProfileFormFields,
  deleteProfileFormValidationSchema,
  initialDeleteProfileFormValues,
} from '../../Profile.definitions';
import { StyledFlex } from './EditProfile/style';

type DeleteAccountFormProps = {
  onClose: () => void;
};

const DeleteAccountForm = ({ onClose }: DeleteAccountFormProps) => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectUser);
  const [reasonIndex, setReasonIndex] = useState(-1);
  const [submitActive, setSubmitActive] = useState(false);
  const [info, setInfo] = useState('');
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = () => {
    deleteProfile(user.userId, reasonsForDelete[reasonIndex], info);
    cleanUpAfterLogout(dispatch, navigate);
    onClose();
    navigate(RouterUrl.Homepage);
  };

  const formikContextValue = {
    initialValues: initialDeleteProfileFormValues,
    validationSchema: deleteProfileFormValidationSchema(t),
    onSubmit: handleDelete,
    validateOnMount: true,
  };
  return (
    <Formik {...formikContextValue}>
      {({ isValid, initialValues, values }) => {
        isValid && setSubmitActive(isValid && !_.isEqual(initialValues, values)); // TODO Refactor set state
        return (
          <StyledFlex>
            <Form>
              <Flex flexDirection="column">
                <FormikDropdown
                  id={DeleteProfileFormFields.Reason}
                  name={DeleteProfileFormFields.Reason}
                  placeholder={`${t('profile.chooseReason')}*`}
                  currentOption={reasonIndex}
                  options={reasonsForDelete.map(reasonForDelete => ({
                    item: t(`profile.reasonsForDelete.${reasonForDelete}`),
                    disabled: false,
                  }))}
                  setIndex={setReasonIndex}
                />
                <FormikTextfield
                  id={DeleteProfileFormFields.Description}
                  name={DeleteProfileFormFields.Description}
                  mt="16px"
                  design="white"
                  placeholder={t('profile.description')}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setInfo(e.target.value);
                  }}
                />

                <Flex gap="16px" mt="41px" flexDirection={['row', 'row', 'row', 'column']}>
                  <Button
                    size={isLarge ? 'large' : 'medium'}
                    width="100%"
                    design="secondary"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    {t('docs.cancel')}
                  </Button>
                  <Button
                    disabled={!submitActive}
                    size={isLarge ? 'large' : 'medium'}
                    width="100%"
                    type="submit"
                    onClick={handleDelete}
                  >
                    {t('profile.deleteAccount')}
                  </Button>
                </Flex>
              </Flex>
            </Form>
          </StyledFlex>
        );
      }}
    </Formik>
  );
};

export { DeleteAccountForm };
