import { css } from 'styled-components';
import { getSelectedLanguage } from '../constants';
import { Language } from '../types';

export const isRtl = () => getSelectedLanguage() === Language.Hebrew;

export const textAlign = (rtl?: boolean) => (rtl ? 'end' : 'start');
export const flexDirection = (rtl?: boolean) => (rtl ? 'row-reverse' : 'row');

export const getSliderMargin = rtl =>
  rtl
    ? css`
        margin-left: auto;
        margin-right: 0;
      `
    : css`
        margin-right: auto;
        margin-left: 0;
      `;

export const iconRevert = css`
  svg {
    transform: scaleX(-1);
  }
`;

export const isHebrew = (text: string) => {
  const hebrewRegex = /^[\u0590-\u05FF ,.'^\r\n-]+$/;
  return hebrewRegex.test(text);
};
