import { Nullable } from 'tsdef';
import { AvatarStatus } from '@beauty/beauty-market-ui';
import { TextWithTranslations } from './general';

export enum NotificationStatus {
  ACTIVE = 'ACTIVE',
  VIEWED = 'VIEWED',
}
export enum NotificationType {
  SALON = 'salon',
  APPOINTMENT = 'appointment',
  PROFILE = 'profile',
  DOCUMENT = 'document',
  REVIEW = 'REVIEW',
}

export type Notification = {
  id: string;
  appointmentId: string;
  type: NotificationType;
  time: string;
  status: AvatarStatus;
  isRead: boolean;
  specialist: Nullable<string>;
  specAvatar: string;
  specialization: string;
  organization: string;
  orgAvatar: string;
  fullAddress: string;
  isReviewed: boolean;
};

export type RenderNotificationsType = {
  new: Notification[];
  archive: Notification[];
};

type AddressInfo = {
  country: string;
  city: string;
  street: string;
  building: string;
  office: string;
  postal: string;
  fullAddress: string;
};

export type NotificationsItemResponse = Notification & {
  status: NotificationStatus;
  createdAt: string;
  address: {
    state: Nullable<string>;
    district: Nullable<string>;
  } & AddressInfo;
  service: TextWithTranslations;
};

export type MapNotification = (notification: NotificationsItemResponse) => Notification;
