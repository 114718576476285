import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { CoverStack, CoverStackCustomer, Flex, Separator } from '@beauty/beauty-market-ui';
import { formatDate } from '../../../../helpers/utils';
import { useMediaScreen } from '../../../../hooks';
import { ProfileType } from '../../../../types/user';
import { PersonalInfoWrapper } from '../style';
import { Info } from './molecules/Info';

type PersonalInfoProps = {
  profile: ProfileType;
  onEditProfileClick: (isOpen: boolean) => void;
};

export const PersonalInfo = ({ profile, onEditProfileClick }: PersonalInfoProps) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMediaScreen('md');

  const profileInfo = [
    {
      title: t('profile.phone'),
      type: 'add',
      value: `${profile.code} ${profile.number}`,
    },
    {
      title: t('profile.e-mail'),
      type: 'add',
      value: profile.email,
    },
    {
      title: t('profile.dateOfBirth'),
      type: 'add',
      value: profile.dateOfBirth ? formatDate(profile.dateOfBirth) : '',
    },
    {
      title: t('profile.gender'),
      type: 'choose',
      value: profile.gender ? t(`profile.genders.${profile.gender.toLowerCase()}`) : '',
    },
    {
      title: t('profile.idNumber'),
      type: 'add',
      value: profile.idNumber ?? '',
    },
    {
      title: t('profile.address'),
      type: 'add',
      value: [profile.fullAddress, profile.city, profile.country].filter(item => Boolean(item)).join(', '),
      truncated: false,
    },
  ];

  const getProfileFields = () => (
    <Flex mt="36px" flexDirection="column">
      {profileInfo.map((info, i) => (
        <React.Fragment key={nanoid()}>
          <Info
            title={info.title}
            type={info.type}
            value={info.value}
            onClick={() => onEditProfileClick(true)}
            truncated={info.truncated}
          />
          {isMobile && i !== profileInfo.length - 1 && <Separator mb="20px" />}
        </React.Fragment>
      ))}
    </Flex>
  );

  return (
    <PersonalInfoWrapper>
      <CoverStack
        header={
          <CoverStackCustomer
            width={isMobile && '100%'}
            buttonDesign="tertiary"
            buttonSize="extraSmall"
            editButtonText={t('profile.editProfile')}
            firstName={profile.name}
            lastName={profile.surname}
            imgUrl={profile.avatarUrl}
            onButtonClick={() => {
              onEditProfileClick(true);
            }}
          />
        }
        content={isDesktop && getProfileFields()}
        width="100%"
      />
      {isMobile && getProfileFields()}
    </PersonalInfoWrapper>
  );
};
