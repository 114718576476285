import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Nullable } from 'tsdef';
import { Flex, Slider } from '@beauty/beauty-market-ui';
import { OrganisationCard } from '../../../components';
import { ServiceIconsBlock } from '../../../components/Services/components/ServiceIconsBlock';
import { getSelectedLanguage, OurChoiceOrganisations } from '../../../constants';
import { isRtl } from '../../../helpers/rtl';
import { getFullOrganisationLink, getTranslatedString, sortByOurChoice } from '../../../helpers/utils';
import { useMediaScreen } from '../../../hooks';
import { useGetOrganisations } from '../../../hooks/useGetOrganisations';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectHistory, setTopCategory } from '../../../store/redux-slices/historySlice';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { OrganisationRenderType } from '../../../types/organisation';
import { AllOffersContainer, OffersGrid, OrganisationsWrapper, StyledButton, StyledPromo } from '../style';

export const OrganisationsSection = () => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isMobile, isDesktop } = useMediaScreen('md');
  const rtl = isRtl();

  const { isForceFetch } = useAppSelector(selectUser);
  const { topCategory } = useAppSelector(selectHistory).history;

  const setSelectedTopCategory = (id: string) => dispatch(setTopCategory(id));
  const { isLoading, organisations } = useGetOrganisations(topCategory, isForceFetch);

  const promoCard = <StyledPromo headline="" title={t('home.placeForBusiness')} isBlueHover />;

  const organizationsList: Nullable<ReactElement[]> = useMemo(() => {
    let list = organisations ? [...sortByOurChoice(organisations)] : [];
    isDesktop && (list = list.slice(0, 6));

    const result = !isLoading
      ? list.map((organisation: OrganisationRenderType) => (
          <OrganisationCard
            id={organisation.id}
            key={organisation.id}
            rating={organisation.rating}
            image={organisation.mainPhoto}
            headline={getTranslatedString(language, organisation.category)}
            title={/* organisation.orgName ? getTranslation(organisation.orgName, language) : */ organisation.name}
            photos={t('organisation.photos', { count: organisation.photoCount ? organisation.photoCount - 1 : 0 })}
            reviews={t('organisation.reviews', { count: organisation.review })}
            badge={OurChoiceOrganisations.includes(organisation.id) ? t('organisation.ourChoice') : ''}
            href={getFullOrganisationLink(
              organisation.id,
              `?org=${organisation.name}&topCategory=${topCategory}&from=Home`,
            )}
            isBlueHover
            isBlank
          />
        ))
      : null;

    result && result.length < 6 && result.push(promoCard);
    return result;
  }, [organisations, topCategory, isMobile, t, isLoading, OurChoiceOrganisations]);

  return (
    <Flex flexDirection="column" backgroundColor="grey" mb={['80px', '80px', '80px', '160px']}>
      <ServiceIconsBlock selected={topCategory} onClick={setSelectedTopCategory} />
      <Flex mt={['32px', '32px', '32px', '40px']}>
        {isMobile ? (
          !isEmpty(organizationsList) && <Slider mb="31px" inlineSlider slides={organizationsList} rtl={rtl} />
        ) : (
          <OrganisationsWrapper>
            <AllOffersContainer>
              <OffersGrid>{organizationsList}</OffersGrid>
            </AllOffersContainer>
          </OrganisationsWrapper>
        )}
      </Flex>
      <Flex justifyContent="center" width={['100%', '100%', '100%', 'auto']}>
        <StyledButton
          onClick={() => {
            navigate(RouterUrl.AllOffers);
          }}
        >
          {t('home.allOffers')}
        </StyledButton>
      </Flex>
    </Flex>
  );
};
