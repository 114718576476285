export { default as About } from './About';
export { default as Banner } from './Banner';
export { default as Contact } from './Contact/Contact';
export { default as HotOffers } from './HotOffers';
export { default as Image } from './Image';
export { default as Offerings } from './Offerings';
export { default as PhotoGallery } from './PhotoGallery';
export { default as Popup } from './Popup';
export { default as Rating } from './Rating/Rating';
export { default as Reviews } from './Reviews/Reviews';
export { default as Team } from './Team';
