import { SearchIcon, CalendarIcon, HeartIcon } from '@beauty/beauty-market-ui';
import { StyledProfileIcon, Wrapper } from './style';

type MenuHandlers = {
  onSearchClick: () => void;
  onCalendarClick: () => void;
  onHeartClick: () => void;
  onProfileClick: () => void;
};

export const MobileMenu = ({ onSearchClick, onCalendarClick, onHeartClick, onProfileClick }: MenuHandlers) => (
  <Wrapper>
    <SearchIcon onClick={onSearchClick} />
    <CalendarIcon onClick={onCalendarClick} />
    <HeartIcon onClick={onHeartClick} />
    <StyledProfileIcon onClick={onProfileClick} />
  </Wrapper>
);
