import { useTranslation } from 'react-i18next';
import { BodySmall, Link, Flex } from '@beauty/beauty-market-ui';
import { isHebrew } from '../../../../../helpers/rtl';
import { useMediaScreen } from '../../../../../hooks';
import { ColumnFlex } from '../../../../HomePage/style';
import { StyledH6 } from './style';

type InfoProps = {
  title: string;
  type: string;
  value: string;
  onClick?: () => void;
  truncated?: boolean;
  long?: boolean;
};

export const Info = ({ title, type, value, onClick, truncated = true, long = false }: InfoProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaScreen('md');
  console.log(isHebrew(value));
  const width = isMobile ? '100%' : (long && '560px') || '242px';

  return (
    <Flex mb={type !== 'change' ? ['20px', '20px', '20px', '40px'] : 0}>
      {value ? (
        <Flex>
          <Flex flexDirection="column">
            <BodySmall>{title}</BodySmall>
            <StyledH6 width={width} truncated={truncated} isHebrew={isHebrew(value)}>
              {value}
            </StyledH6>
          </Flex>
          {/* {type === 'change' && (
            <Link size="s" mt="10px" onClick={onClick}>
              {t('profile.change')}
            </Link>
          )} */}
        </Flex>
      ) : (
        <ColumnFlex>
          <BodySmall mb="4px">{title}</BodySmall>
          <Link size="s" mt="10px" onClick={onClick}>
            {type === 'add' ? t('profile.add') : t('profile.choose')}
          </Link>
        </ColumnFlex>
      )}
    </Flex>
  );
};
