import { useEffect, useMemo, useRef } from 'react';
import { Element } from 'react-scroll';
import { EventCard } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../../constants';
import { dateFormatter, timeFormatter } from '../../../../helpers/appointments';
import { getTranslation, scrollTo } from '../../../../helpers/utils';
import { useMediaScreen } from '../../../../hooks/useMediaScreen';
import { AppointmentType } from '../../../../types/appointment';
import { EventStatus } from '../constants';
import { CardsWrapper, ScrollWrapper } from '../style';

type EventsListProps = {
  events: AppointmentType[];
  selected: AppointmentType | undefined;
  onButtonClick: (id: string) => void;
};

const eventsContainerId = 'eventsContainer';

export const EventsList = ({ events, selected, onButtonClick }: EventsListProps) => {
  const { isMobile } = useMediaScreen('md');

  const language = getSelectedLanguage();
  const eventsRef = useRef<Element>();

  const cardStatus = (receivedStatus: EventStatus) => {
    if (receivedStatus === EventStatus.INPROGRESS) return EventStatus.CONFIRMED;
    if (receivedStatus === EventStatus.CANCELLED || receivedStatus === EventStatus.NOSHOW) return EventStatus.CANCELLED;
    if (receivedStatus === EventStatus.UNCLOSED) return EventStatus.PAST;
    return receivedStatus;
  };

  const cards = useMemo(
    () => (
      <CardsWrapper id={eventsContainerId} ref={eventsRef}>
        {events.map(event => (
          <Element data-element-identifier={event.id} name={`event_${event.id}`} key={event.id}>
            <EventCard
              key={event.id}
              label={timeFormatter('RU').format(new Date(event.start))}
              placeholder={getTranslation(event.orgService.headOrgService.title, language)}
              organisation={event.orgService.organization.name}
              status={cardStatus(event.status as unknown as EventStatus)}
              title={dateFormatter(language).format(new Date(event.start))}
              isActive={event.id === selected?.id}
              onClick={() => onButtonClick(event.id)}
            />
          </Element>
        ))}
      </CardsWrapper>
    ),
    [events, selected, language],
  );

  useEffect(() => {
    selected?.id && scrollTo(`event_${selected.id}`, eventsContainerId, isMobile);
  }, [selected?.id]);

  return <ScrollWrapper>{cards}</ScrollWrapper>;
};
