import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Button, Separator, Accordion, BodySmall, Flex } from '@beauty/beauty-market-ui';
import { setCookiesAccepted } from '../../api/api.helpers';
import { SidebarSheet } from '../SidebarSheet';
import { HeaderAccordion } from './components/HeaderAccordion/HeaderAccordion';
import { ColumnFlex } from './style';

interface CookieSidebarProps {
  setIsCookieSettingOpen: () => void;
  isCookieSettingOpen: boolean;
}

const accordionData = (t: TFunction<'translation', undefined>) => [
  { text: t('cookies.necessary'), description: t('cookies.always'), extraText: t('cookies.explanation') },
  { text: t('cookies.performance'), description: '', extraText: '' },
  { text: t('cookies.functional'), description: '', extraText: '' },
  { text: t('cookies.targeting'), description: '', extraText: '' },
];

export const CookieSidebar = ({ setIsCookieSettingOpen, isCookieSettingOpen }: CookieSidebarProps) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setIsCookieSettingOpen();
    setCookiesAccepted('false');
  };

  const handleRejectCookies = () => {
    setIsCookieSettingOpen();
    setCookiesAccepted('false');
  };

  const handleAcceptCookies = () => {
    setIsCookieSettingOpen();
    setCookiesAccepted('true');
  };

  const FooterBody = (
    <Flex flexDirection="column" width="100%" gap="16px">
      <Button size="large" width="100%" onClick={handleAcceptCookies}>
        {t('cookies.acceptance')}
      </Button>
      <Button size="large" width="100%" design="secondary" onClick={handleRejectCookies}>
        {t('cookies.reject')}
      </Button>
    </Flex>
  );

  const content = (
    <>
      <BodySmall>{t('cookies.description')}</BodySmall>
      <ColumnFlex>
        {accordionData(t).map(data => (
          <React.Fragment key={data.text}>
            <Accordion customElement={<HeaderAccordion text={data.text} description={data.description} />}>
              {data.extraText}
            </Accordion>
            <Separator mt="21px" mb="27px" />
          </React.Fragment>
        ))}
      </ColumnFlex>
    </>
  );

  const someSidebarProps = {
    label: t('cookies.setting'),
    descriptor: t('cookies.subtitle'),
    isOpen: isCookieSettingOpen,
    onClose: handleClose,
    FooterBody,
  };

  return <SidebarSheet {...someSidebarProps}>{content}</SidebarSheet>;
};
