import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fakeFavourites } from '../page/Client/Favourites/constants';
import { FavouritesOffer } from '../page/Client/Favourites/types';
// import { apiRequest } from '../api/api';
// import { FavouritesEndpoints } from '../api/endpoints/endpoints';

export const useGetFavourites = (userId: string | undefined) => {
  const [isLoading, setIsLoading] = useState(false);
  const [favourites, setFavourites] = useState<FavouritesOffer[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    let isFavouritesLoading = true;

    const fetchFavourites = async () => {
      setIsLoading(true);
      // const [, response] = await apiRequest.get({
      //   endpoint: FavouritesEndpoints.Root,
      //   id: userId
      // });

      if (isFavouritesLoading) {
        setFavourites(fakeFavourites(t));
        //  setCategories(response?.data);
        setIsLoading(false);
      }
    };

    if (userId) fetchFavourites();

    return () => {
      isFavouritesLoading = false;
    };
  }, [userId, t]);

  return {
    isLoading,
    favourites,
  };
};
