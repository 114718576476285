import styled from 'styled-components';
import { Div, Flex, mediaQueries } from '@beauty/beauty-market-ui';
import { zIndex } from '../../constants';
import Container from '../Container';

export const Wrapper = styled(Div)<{ disabled: boolean }>`
  ${({ disabled }) => `z-index: ${disabled ? zIndex.bookingPopupDisabled : zIndex.bookingPopupActive};`}

  position: fixed;
  bottom: 0;
  height: 120px;
  width: 100%;
  background-color: #ffffff;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.08)) drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.08));
`;

export const Layout = styled(Div)`
  height: inherit;
  max-width: 1440px;
  margin: 0 auto;
`;

export const Content = styled(Container)`
  height: inherit;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  ${mediaQueries.md} {
    max-width: 1060px;
    width: 100%;
  }
`;

export const RightBlock = styled(Flex)`
  gap: 40px;
  align-items: center;

  ${mediaQueries.md} {
    width: calc(100vw - 360px);
    max-width: 730px;
  }
`;
