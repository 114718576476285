import { SpecialistsRoles } from '../../constants';

export const bannerProps = organisation => ({
  image: organisation.mainPhoto,
  photosNum: Number(organisation.photosNum),
  // dropTime: organisation.dropTime,
  workSchedule: organisation.workSchedule,
});

export const contactsProps = organisation => ({
  address: organisation.address,
  phone: organisation.phone,
  email: organisation.email,
  name: organisation.name,
  instagram: organisation.instagram,
});

export const aboutProps = organisation => ({
  description: organisation.description,
  orgDescr: organisation.orgDescr,
  photo: organisation.photo,
  team: organisation.team.filter(member => member.role === SpecialistsRoles.SPECIALIST),
  // reviews: organisation.reviews,
  organisationName: organisation.name,
  offers: {
    categories: organisation.offers.categories,
  },
  orgTimezone: organisation.timezone,
  orgId: organisation.id,
  property: organisation.property,
});
