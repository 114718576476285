import { FacebookMonoIcon, TelegramMonoIcon, InstagramMonoIcon, MailFillIcon } from '@beauty/beauty-market-ui';
import { PARTNER_TERMS_URL } from '../../constants';
import { RouterUrl } from '../../routes/routes';

export const contactsColumn = [
  {
    text: 'extendedFooter.customerHelpCentre',
    link: '',
  },
  {
    text: 'extendedFooter.contactUs',
    link: '',
  },
];

export const companyColumn = [
  {
    text: 'extendedFooter.aboutUs',
    link: RouterUrl.AboutUs,
  },
  {
    text: 'extendedFooter.contactUs',
    link: RouterUrl.ContactUs,
  },
  {
    text: 'extendedFooter.faq',
    link: RouterUrl.FAQ,
  },
  {
    text: 'extendedFooter.customerHelpCenter',
    link: RouterUrl.Support,
  },
];

export const businessColumn = [
  {
    text: 'extendedFooter.bmForBusiness',
    link: RouterUrl.ForBusiness,
  },
  {
    text: 'extendedFooter.partner',
    link: PARTNER_TERMS_URL,
    inBlank: true,
  },
];

export const legalsColumn = [
  {
    text: 'extendedFooter.privacyPolicy',
    link: RouterUrl.PrivacyPolicy,
  },
  {
    text: 'extendedFooter.termsOfService',
    link: RouterUrl.TermsOfService,
  },
  {
    text: 'extendedFooter.termsOfUse',
    link: RouterUrl.TermsOfUse,
  },
];

export const socialNetworks = [
  {
    icon: <MailFillIcon />,
    text: 'extendedFooter.textUs',
    link: 'mailto:support@beautymarket.co.il',
  },
  {
    icon: <FacebookMonoIcon />,
    text: 'socialNetwork.facebook',
    link: 'https://www.facebook.com/profile.php?id=61551185395291',
  },
  {
    icon: <InstagramMonoIcon />,
    text: 'socialNetwork.instagram',
    link: 'https://www.instagram.com/beautymarket_israel/',
  },
  {
    icon: <TelegramMonoIcon />,
    text: 'socialNetwork.telegram',
    link: 'https://t.me/beautymarket_israel',
  },
];
