import styled, { css } from 'styled-components';
import { colors, Button, Flex, mediaQueries, Display, H2, CheckedIcon, BodyLarge } from '@beauty/beauty-market-ui';
import { Container } from '../../components';
import { zIndex } from '../../constants';
import { textAlign } from '../../helpers/rtl';
import { bubbleHeight, bubbles, bubbleWidth, titleShadow } from '../../style';

export const bubbleStyle = css`
  content: '';
  z-index: ${zIndex.minusOne};
  left: calc((100% - ${`${bubbleWidth}px`}) / 2);
  ${bubbles};
  background-size: auto;

  ${mediaQueries.md} {
    background-size: 100%;
  }
`;

export const StyledContainer = styled(Container)`
  position: relative;
  flex-direction: column;
  width: auto !important;
  max-width: 1060px;
`;

export const TopWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
  z-index: 1;
  ${mediaQueries.md} {
    margin-bottom: 0;
  }

  &:before {
    ${bubbleStyle};
    width: 1200px;
    height: 1200px;
    left: calc((100% - 250px - ${`${bubbleWidth}px`}) / 2);
    top: calc((100% - 650px - ${`${bubbleHeight}px`}) / 2);

    ${mediaQueries.md} {
      background-size: 108%;
      left: calc((100% - 250px - ${`${bubbleWidth}px`}) / 2);
      top: -300px;
    }
  }
`;

export const TopTitle = styled(Display)`
  width: 100%;
  max-width: 820px;
  margin: 80px 0 24px !important;
  text-align: center;
  color: ${colors.white.standard}!important;
  ${titleShadow};
  letter-spacing: -2.4px;

  ${mediaQueries.md} {
    margin: 160px 95px 24px !important;
  }

  @media screen and (min-width: 650px) {
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
`;

export const TopImageWrapper = styled(Flex)`
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  img {
    max-width: 1280px;
  }

  @media screen and (max-width: 1060px) {
    img {
      width: 120%;
    }
  }
`;

export const StyledButton = styled(Button)`
  margin: 32px 0 !important;
  font-weight: 500 !important;
  width: 100%;

  ${mediaQueries.md} {
    width: fit-content;
    margin: 40px auto 64px !important;
  }

  background-color: ${colors.black.standard}!important;
  border-color: ${colors.black.standard}!important;
  display: block !important;
`;

export const CardButton = styled(Button)`
  margin: 24px auto 0 !important;
  background-color: ${colors.black.standard}!important;
  border-color: ${colors.black.standard}!important;
`;

export const CardsWrapper = styled(Flex)`
  flex-wrap: wrap;
  padding: 0;
  gap: 16px;
  max-width: 840px;
  position: relative;

  ${mediaQueries.md} {
    padding-left: 105px;
    padding-right: 105px;
  }

  &:before {
    ${bubbleStyle};
    top: 150px;

    ${mediaQueries.md} {
      top: -150px;
      background-size: 110%;
    }
  }
`;

export const AdaptiveFlex = styled(Flex)<{ gap: string[] }>`
  ${({ gap }) => css`
    flex-direction: column;
    gap: ${gap[0]};

    ${mediaQueries.md} {
      gap: ${gap[1]};
      flex-direction: row;
    }
  `}
`;

export const StyledFlex = styled(Flex)<{ maxWidth?: string[] }>`
  gap: 16px;
  padding: 32px 16px;
  flex-direction: column;
  text-align: ${({ theme }) => (theme.rtl ? 'right' : 'left')};
  border: 1px solid ${colors.grey.light};
  box-shadow:
    0 2px 24px 0 rgba(0, 0, 0, 0.08),
    0 0 2px 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  box-sizing: border-box;

  ${({ maxWidth }) => css`
    max-width: ${maxWidth && maxWidth[0]}!important;
    ${mediaQueries.lg} {
      max-width: ${maxWidth && maxWidth[1]}!important;
    }
  `};

  ${mediaQueries.md} {
    padding: 32px;
  }
`;

export const BenefitFlex = styled(Flex)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
  padding: 0;

  ${mediaQueries.md} {
    width: 510px;
    padding: 0 80px;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    padding: 0;
  }
`;

export const BenefitWrapper = styled(Flex)<{ flexDirection: string[] }>`
  gap: 40px;
  ${({ flexDirection }) => css`
    flex-direction: ${flexDirection[0]}!important;
    ${mediaQueries.lg} {
      gap: 16px;
      flex-direction: ${flexDirection[1]}!important;
    }
  `}
`;

export const HelpWrapper = styled(Flex)`
  flex-direction: column;
  margin-bottom: 80px;
  align-items: center;
  position: relative;

  ${mediaQueries.md} {
    margin-bottom: 160px;
  }

  &:before {
    ${bubbleStyle};
    top: -350px;

    ${mediaQueries.md} {
      background-size: 110%;
    }
  }

  & > h2:first-child,
  & > p:nth-child(2) {
    color: ${colors.white.standard};
  }
`;

export const HelpTitle = styled(H2)`
  margin-bottom: 24px !important;
  ${titleShadow};
  width: 100%;
  max-width: 620px;

  ${mediaQueries.md} {
    margin-bottom: 16px !important;
  }
`;

export const ManagingWrapper = styled(Flex)`
  flex-direction: column;
  margin-bottom: 80px;
  position: relative;

  ${mediaQueries.md} {
    margin-bottom: 160px;
  }

  &:before {
    ${bubbleStyle};
    top: -350px;
  }

  &:after {
    ${mediaQueries.md} {
      ${bubbleStyle};
      top: 0;
    }
  }

  h2 {
    color: ${colors.white.standard};
  }
`;

export const ManagingTitle = styled(H2)`
  text-align: center;
  margin: 0 auto 56px !important;
  ${titleShadow};
  width: 100%;
  max-width: 620px;

  ${mediaQueries.md} {
    margin-bottom: 80px !important;
  }
`;

export const ManagingCardWrapper = styled(Flex)`
  flex-direction: column;
  flex: 1;
  z-index: ${zIndex.managingCardWrapper};
  box-shadow:
    0 0 2px rgba(0, 0, 0, 0.08),
    0 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  box-sizing: border-box;
  align-items: center;
  padding: 24px 16px;
  background-color: ${colors.white.standard};

  img {
    width: 100px;
    height: 100px;
  }

  ${mediaQueries.md} {
    width: 100%;

    img {
      width: 200px;
      height: 200px;
    }
  }
`;

export const BottomWrapper = styled(Flex)`
  position: relative;
  gap: 26px;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  padding: 0 0 120px;

  ${mediaQueries.md} {
    flex-direction: row;
    gap: 40px;
  }

  &:after {
    box-sizing: border-box;
    content: '';
    z-index: ${zIndex.minusOne};
    position: absolute;
    height: 100%;
    left: calc(0.5vw - 24px);

    ${mediaQueries.lg} {
      height: 568px;
      max-width: 1424px;
    }

    background-color: ${colors.blue.standard};
    border-radius: 40px;
    width: 99vw;
    margin: auto;

    @media screen and (min-width: 1060px) {
      left: calc(530px - 50vw + 5px);
    }
    @media screen and (min-width: 1441px) {
      left: -182px;
    }
  }
`;

export const ImageWrapper = styled(Flex)`
  position: relative;
  justify-content: center;

  img {
    width: 100%;
    z-index: ${zIndex.zero};
  }

  ${mediaQueries.md} {
    img {
      max-width: 580px;
    }
  }

  &:before {
    ${bubbleStyle};
    z-index: ${zIndex.zero};
    top: 0;

    ${mediaQueries.md} {
      top: -50%;
    }
  }
`;

export const StyledImg = styled.img`
  width: 100%;

  ${mediaQueries.lg} {
    width: 50%;
  }
`;

export const ColoredDisplay = styled(Display)`
  margin-bottom: 80px !important;
  text-align: center;
  letter-spacing: -2.4px;

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, ${colors.blue.standard}, ${colors.red.standard});

  ${mediaQueries.md} {
    margin-bottom: 160px !important;
  }
`;

export const InfoWrapper = styled(Flex)`
  flex-direction: column;
  padding: 16px 0 0;
  box-sizing: border-box;
  height: auto;

  ${mediaQueries.md} {
    padding: 40px 16px 0;
    max-width: 337px;
  }

  * {
    text-align: center;
  }
`;

export const StyledCheckedIcon = styled(CheckedIcon)<{ color: string }>`
  justify-self: center;
  width: 24px;
  height: 24px;

  circle {
    fill: ${({ color }) => color};
  }

  path {
    stroke: ${({ theme }) => theme.colors.white.standard};
  }
`;

export const TariffPlansWrapper = styled(Flex)`
  flex-direction: column;
  position: relative;
  gap: 55px;

  ${mediaQueries.md} {
    gap: 130px;
  }

  &:before {
    ${bubbleStyle};
    top: -350px;
    ${mediaQueries.md} {
      top: -150px;
    }
  }
`;

export const TariffSubtitle = styled(BodyLarge)`
  width: 100%;
  max-width: 820px;
  margin: 0 auto !important;
  text-align: center;
  color: ${({ theme }) => theme.colors.white.standard}!important;
`;

export const BottomContent = styled(Flex)`
  position: relative;
  flex-direction: column;
  max-width: 465px;
  z-index: ${zIndex.one};

  *:not(button) {
    color: ${colors.white.standard};
  }

  justify-content: center;
  align-items: center;
  text-align: center;

  ${mediaQueries.md} {
    align-items: start;
    text-align: start;
  }

  &:before {
    ${bubbleStyle};

    ${mediaQueries.md} {
      top: -100%;
    }
  }
`;
