import { useCallback, useState } from 'react';

export const useFullImage = (length: number) => {
  const [index, setIndex] = useState<number>(-1);

  const onPrevArrowClick = length > 1 ? () => setIndex(index > 0 ? index - 1 : length - 1) : null;
  const onNextArrowClick = length > 1 ? () => setIndex(index < length - 1 ? index + 1 : 0) : null;

  const onClose = useCallback(() => {
    setIndex(-1);
  }, []);

  return {
    onClose,
    index,
    setIndex,
    isOpen: index > -1,
    onPrevArrowClick,
    onNextArrowClick,
  };
};
