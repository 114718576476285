import { useTranslation } from 'react-i18next';
import { H4, BodySmall, colors } from '@beauty/beauty-market-ui';
import InfoIcon from '../../../../assets/info-image.png';
import { NoFavouritesWrapper } from '../style';

const NoFavourites = () => {
  const { t } = useTranslation();
  return (
    <NoFavouritesWrapper>
      <img src={InfoIcon} alt="FQA" />
      <H4 mt="25px" mb="0">
        {t('favourites.empty.title')}
      </H4>
      <BodySmall mt="8px" color={colors.grey.dark}>
        ({t('favourites.empty.body')})
      </BodySmall>
    </NoFavouritesWrapper>
  );
};

export default NoFavourites;
