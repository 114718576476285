import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { BodyLarge, Button, Flex, NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { FormikPhone } from '../../../../../components';
import { invalidHints } from '../../../../../constants';
import { getInvalidType, getShortCountryByCode } from '../../../../../helpers';
import { getUserNotifyContent } from '../../../../../helpers/notifyContent/user';
import { updatePhone } from '../../../../../helpers/profile';
import { useMediaScreen } from '../../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectUser, updateSmsCodeTime } from '../../../../../store/redux-slices/userSlice';
import { UserActions } from '../../../../../types/user';
import {
  LoginByPhoneFields,
  LoginByPhoneForm,
  loginPhoneFormValidationSchema,
} from '../../../../Login/Login.definitions';

type ChangePhoneFormProps = {
  onSmsCodeFormOpen: () => void;
  setNewRegion: (region: string) => void;
  setNewNumber: (number: string) => void;
};

export const ChangePhoneForm = ({ onSmsCodeFormOpen, setNewRegion, setNewNumber }: ChangePhoneFormProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');

  const user = useAppSelector(selectUser);

  const dispatch = useAppDispatch();
  const notify = useNotify();

  const { Region, Number } = LoginByPhoneFields;

  const onSubmit = async ({ code, number }: LoginByPhoneForm, { setFieldError }) => {
    const prevPhone = parsePhoneNumber(`${user.user.code} ${user.user.number}`, getShortCountryByCode(code));
    const newPhone = parsePhoneNumber(`${code} ${number}`, getShortCountryByCode(code));

    const isEqual = prevPhone?.isValid() && newPhone?.isValid() && prevPhone.isEqual(newPhone);
    if (isEqual) {
      const errorMessage = t('validationNew.phonesSame');
      notify(getUserNotifyContent(NotifyStatus.Error, UserActions.UpdatePhone, t, errorMessage));
      setFieldError(Number, errorMessage);
      return;
    }

    const payload = { code, number: newPhone.nationalNumber };
    const response = await updatePhone(payload);
    if (response?.status === 201) {
      setNewRegion(payload.code);
      setNewNumber(payload.number);
      onSmsCodeFormOpen();
      dispatch(updateSmsCodeTime(new Date().getTime()));
    } else {
      const errorCode = response?.status && getInvalidType(response.status, 'changePhone');
      errorCode && setFieldError(Number, t(invalidHints[errorCode]));
      notify(getUserNotifyContent(NotifyStatus.Error, UserActions.UpdatePhone, t));
    }
  };

  const formikContextValue = {
    initialValues: {
      [Region]: '',
      [Number]: '',
    },
    validationSchema: loginPhoneFormValidationSchema(t),
    onSubmit,
    validateOnMount: false,
  };

  return (
    <Formik {...formikContextValue}>
      {({ isValid, isSubmitting }) => (
        <Form style={{ width: '100%' }}>
          <Flex width="100%" flexDirection="column">
            <BodyLarge mb="16px">{t('profile.enterNewPhone')}</BodyLarge>
            <FormikPhone codeId={Region} phoneId={Number} placeholder={t('profile.phone')} required />
            <Flex mt="41px">
              <Button
                disabled={!isValid || isSubmitting}
                size={isDesktop ? 'large' : 'medium'}
                width="100%"
                type="submit"
              >
                {t('profile.change')}
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
