import React, { ChangeEvent, useState } from 'react';
import { Flex, BodySmall, Toggle, colors } from '@beauty/beauty-market-ui';
import { Wrapper } from './style';

type HeaderAccordionProps = {
  text: string;
  description?: string;
};

export const HeaderAccordion = ({ text, description }: HeaderAccordionProps) => {
  const [checked, setChecked] = useState(true);
  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.currentTarget.checked);
  };

  return (
    <Wrapper>
      <Flex flexDirection="column">
        <BodySmall regular>{text}</BodySmall>
        {description && <BodySmall color={colors.grey.dark}>{description}</BodySmall>}
      </Flex>
      <Toggle size="small" checked={checked} onChange={handleToggle} />
    </Wrapper>
  );
};
