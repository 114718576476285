import { BodySmall, H6, Flex } from '@beauty/beauty-market-ui';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { GreyBodySmall } from '../style';

const SelectedService = () => {
  const { booking } = useAppSelector(selectUser);

  return booking.service ? (
    <Flex gap="2px" flexDirection="column" justifyContent="center" width="inherit">
      <BodySmall>{booking.service.label}</BodySmall>
      <H6>{booking.service.price}</H6>
      <GreyBodySmall lowline>{booking.service.description}</GreyBodySmall>
    </Flex>
  ) : null;
};

export default SelectedService;
