import { Nullable } from 'tsdef';

export type Text = {
  id: string;
  langId: string;
  text: string;
};

export type TextWithTranslations =
  | ({
      translation: Text[] | [];
    } & Text)
  | null;

export type TopCategory = {
  id: string;
  title: TextWithTranslations;
};

export type TopCategoryWithIcon = {
  icon: JSX.Element;
  name: string;
  id: string | null;
};

export type GeolocationType = {
  lng: number;
  lat: number;
};

export type BoundsType = {
  NE: GeolocationType;
  SW: GeolocationType;
};

export type FoundResponse = {
  id: string;
  name: string;
  descr: Nullable<string>;
  mainPhoto: Nullable<string>;
  category: TextWithTranslations[];
  photoCount: number;
  rating: Nullable<string>;
  review: number;
  field: string[];
  email: string;
  code: string;
  number: string;
  address: string;
  coordinates: { lat: number; lng: number };
};

export type Person = {
  name: string;
  surname: string;
};

export enum Language {
  English = 'EN',
  Hebrew = 'HE',
  Russian = 'RU',
  Ukrainian = 'UA',
  French = 'FR',
  German = 'DE',
  Spanish = 'ES',
  Arabic = 'AR',
  Amharic = 'AM',
}

export enum Level {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
  Native = 'NATIVE',
}

export enum SocialNetwork {
  Instagram = 'INSTAGRAM',
  Telegram = 'TELEGRAM',
  Whatsapp = 'WHATSAPP',
}

export type SpokenLanguage = {
  level: Level;
  language: Language;
};

export type Order = 'date_asc' | 'date_desc';

export type Document = {
  id: string;
  url: string;
};

export type Education = {
  id: string;
  startYear: number;
  endYear: number;
  field: string;
  school: string;
  degree: string;
  file?: string;
  fileName?: string | null;
};

export type Property = {
  id: string;
  iconUrl: string;
  title: string;
};
