import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
  Popup,
  Button,
  Flex,
  InstagramMonoIcon,
  FacebookMonoIcon,
  MailFillIcon,
  ShareIcon,
  useMediaQuery,
  BottomSheet,
  getMinWidthMediaQuery,
} from '@beauty/beauty-market-ui';
import { shareOrganisation } from '../../../helpers/utils';
import { ReversedIcon, UnstrokedIcon } from '../../../hoc';

interface ShareOrganisationProps {
  handelModalClose: () => void;
  isShareModalOpen: boolean;
  organisationId: string;
}

export const ShareOrganisationModal = ({
  handelModalClose,
  isShareModalOpen,
  organisationId,
}: ShareOrganisationProps) => {
  const { t } = useTranslation();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const shareClick = (sharePlace: string) => {
    handelModalClose();
    shareOrganisation(organisationId, sharePlace);
  };

  const FooterBody = (
    <Flex flexDirection="column" width="100%">
      <Flex justifyContent={isLarge ? 'space-between' : ''} flexDirection={['column', 'row']} mb="16px" gap="16px">
        <Button size="small" design="secondary" width="100%" onClick={() => shareClick('facebook')}>
          <FacebookMonoIcon />
          Facebook
        </Button>
        <Button design="secondary" size="small" width="100%" onClick={() => shareClick('instagram')}>
          <UnstrokedIcon>
            <InstagramMonoIcon />
          </UnstrokedIcon>
          Instagram
        </Button>
      </Flex>

      <Flex justifyContent={isLarge ? 'space-between' : ''} flexDirection={['column', 'row']} gap="16px">
        <Button size="small" design="secondary" width="100%" onClick={() => shareClick('email')}>
          <UnstrokedIcon>
            <MailFillIcon />
          </UnstrokedIcon>

          {t('share.email')}
        </Button>
        <Button design="secondary" size="small" width="100%" onClick={() => shareClick('copy')}>
          <ReversedIcon>
            <ShareIcon />
          </ReversedIcon>

          {t('share.link')}
        </Button>
      </Flex>
    </Flex>
  );

  return createPortal(
    isLarge ? (
      <Popup
        title={t('share.title')}
        info={t('share.subtitle')}
        open={isShareModalOpen}
        handleClose={handelModalClose}
        FooterBody={FooterBody}
      />
    ) : (
      <BottomSheet
        isOpen={isShareModalOpen}
        onClose={handelModalClose}
        handleClose={handelModalClose}
        label={t('share.title')}
        descriptor={t('share.subtitle')}
        content={FooterBody}
        detent="content-height"
      />
    ),
    document.getElementById('root') as HTMLElement,
  );
};
