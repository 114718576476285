import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { Flex, H2, H4, H3, BodySmall, BodyLarge, Separator, Div, Alert, AlertTypes } from '@beauty/beauty-market-ui';
import { SendMessageForm } from './SendMessageForm';
import { NotReversedFlex, StyledContainer, StyledFlex } from './style';

export const ContactUs = () => {
  const { t } = useTranslation();
  const [alertType, setAlertType] = useState<Nullable<AlertTypes>>(null);

  const alertProps = useMemo(
    () => ({
      isAlertVisible: !!alertType,
      type: alertType,
      title: alertType === AlertTypes.SUCCESS ? t('contactUs.successTitle') : t('contactUs.errorTitle'),
      subtitle: alertType === AlertTypes.SUCCESS ? t('contactUs.successDescription') : t('contactUs.errorDescription'),
    }),
    [alertType],
  );

  useEffect(() => {
    alertType &&
      setTimeout(() => {
        setAlertType(null);
      }, 5100);
  }, [alertType]);

  return (
    <>
      <StyledContainer mt="72px" flexDirection="column">
        <StyledFlex>
          <H2 mb="24px" width="100%">
            {t('contactUs.title')}
          </H2>
          <Flex width="100%" flexDirection="column">
            <BodyLarge mb="8px">{t('contactUs.info')}</BodyLarge>
            <H4>info@beautymarket.co.il</H4>
          </Flex>
        </StyledFlex>
        <Div mb="86px">
          <Separator />
        </Div>
        <NotReversedFlex>
          <Flex width="100%" flexDirection="column">
            <BodySmall mb="8px">{t('contactUs.hint')}</BodySmall>
            <H3 mb="24px">{t('contactUs.sendMessage')}</H3>
          </Flex>
          <SendMessageForm setAlertType={setAlertType} />
        </NotReversedFlex>
      </StyledContainer>
      <Alert {...alertProps} />
    </>
  );
};
