import { MutableRefObject, useEffect, useState } from 'react';
import { Link, Slider } from '@beauty/beauty-market-ui';
import { isRtl } from '../../helpers/rtl';
import { useMediaScreen } from '../../hooks';
import { MenuWrapper } from './style';

type AnchorMenuProps = {
  menuItems: string[];
  hrefs: MutableRefObject<Element[]>;
  activeSection?: number;
  onClick?: (isClicked: boolean) => void;
};

export const AnchorMenu = ({ menuItems, activeSection, hrefs, onClick }: AnchorMenuProps) => {
  const [active, setActive] = useState<number>(-1);
  const executeScroll = (i: number) => hrefs.current[i].scrollIntoView({ behavior: 'smooth', block: 'start' });

  const { isMobile } = useMediaScreen('md');
  const rtl = isRtl();

  const handleClick = (i: number) => {
    setActive(i);
    executeScroll(i);
    onClick && onClick(true);

    setTimeout(() => {
      onClick && onClick(false);
    }, 1000);
  };

  const renderLink = (item, i) => (
    <Link
      key={i}
      width={isMobile ? 'max-content' : 'auto'}
      design={i === active ? 'blue' : 'black'}
      size="md"
      onClick={() => handleClick(i)}
    >
      {item}
    </Link>
  );

  const content = menuItems.map(renderLink);

  useEffect(() => {
    setActive(activeSection ?? -1);
  }, [activeSection]);

  return <MenuWrapper>{isMobile ? <Slider inlineSlider slides={content} rtl={rtl} /> : content}</MenuWrapper>;
};
