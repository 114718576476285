import { TFunction } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import * as Yup from 'yup';
import { emailReg, passwordReg } from '../../constants';
import { getShortCountryByCode } from '../../helpers';

// log in by phone
export enum LoginByPhoneFields {
  Number = 'number',
  Region = 'code',
}

export type LoginByPhoneForm = {
  [LoginByPhoneFields.Number]: string;
  [LoginByPhoneFields.Region]: string;
};

export const initialByPhoneValues: LoginByPhoneForm = {
  [LoginByPhoneFields.Number]: '',
  [LoginByPhoneFields.Region]: '+972',
};

export const loginPhoneFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [LoginByPhoneFields.Region]: Yup.string().trim(),
    [LoginByPhoneFields.Number]: Yup.string()
      .trim()
      .required(t('validation.phoneRequired'))
      .test('validate-phone', t('validation.enterValidPhone'), function validatePhone(phone) {
        const code = this.parent[LoginByPhoneFields.Region];
        if (code) {
          return isValidPhoneNumber(`${code} ${phone}`, getShortCountryByCode(code));
        }
        return false;
      }),
  });

// log in by email
export enum LoginByEmailFields {
  Email = 'email',
  Password = 'password',
}

export type LoginByEmailForm = {
  [LoginByEmailFields.Email]: string;
  [LoginByEmailFields.Password]: string;
};

export const initialByEmailValues: LoginByEmailForm = {
  [LoginByEmailFields.Email]: '',
  [LoginByEmailFields.Password]: '',
};

export const loginEmailFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [LoginByEmailFields.Email]: Yup.string()
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
    [LoginByEmailFields.Password]: Yup.string()
      .required(t('validation.notEmpty'))
      .min(8, t('validation.minPassword'))
      .matches(passwordReg, t('validation.passwordHint')),
  });

// sms code form
export enum LoginFormSmsCodeFields {
  SMSCode = 'smscode',
}

export type LoginSmsCodeForm = {
  [LoginFormSmsCodeFields.SMSCode]: string;
};

export const initialSmsCodeValues: LoginSmsCodeForm = {
  [LoginFormSmsCodeFields.SMSCode]: '',
};

export const loginSMSCodeFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [LoginFormSmsCodeFields.SMSCode]: Yup.string()
      .required(t('validation.notEmpty'))
      .matches(/^\d{6}$/, t('validation.smsCode')),
  });

// email
export enum EmailField {
  Email = 'email',
}

export type EmailForm = {
  [EmailField.Email]: string;
};

export const initialEmailValue: EmailForm = {
  [EmailField.Email]: '',
};

export const emailSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [EmailField.Email]: Yup.string()
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
  });

export const checkEmailSchema = (message: string, t: TFunction<'translation', undefined>) =>
  Yup.object({
    [EmailField.Email]: Yup.string()
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
  });

// reset password
export enum ResetPasswordField {
  Password = 'password',
}

export type ResetPasswordForm = {
  [ResetPasswordField.Password]: string;
};

export const initialResetPasswordValues: ResetPasswordForm = {
  [ResetPasswordField.Password]: '',
};

export const resetPasswordSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [ResetPasswordField.Password]: Yup.string()
      .required(t('validation.notEmpty'))
      .min(8, t('validation.minPassword'))
      .matches(passwordReg, t('validation.passwordHint')),
  });
