import styled, { css } from 'styled-components';
import { Div, Flex, H4, mediaQueries, Button, BodySmall, Caption } from '@beauty/beauty-market-ui';
import Bubble from './assets/Bubble.png';
import Container from './components/Container/Container';

export const AppWrapper = styled(Div)`
  margin: auto;
  min-height: 100%;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: [row1] 80px [row2] 1fr [row3] 70px [row4];
  grid-template-areas:
    'header'
    'main'
    'footer'
    'footer';
`;

export const bubbleWidth = '950';
export const bubbleHeight = '950';

export const bubbles = css`
  position: absolute;
  background-image: url(${Bubble});
  background-repeat: no-repeat;
  background-position: center center;
  width: ${`${bubbleWidth}px`};
  height: ${`${bubbleHeight}px`};
  @keyframes anim {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation: anim 30s linear infinite;
`;

export const titleShadow = css`
  text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.24);
`;

export const StyledUl = styled.ul<{ type?: string }>`
  margin: 0;
  padding: 0;
  [dir='ltr'] & {
    padding-left: 24px;
  }
  [dir='rtl'] & {
    padding-right: 24px;
  }
  list-style-position: outside;
  ${({ type }) => type && `list-style-type:${type}`}
`;

export const StyledOl = styled.ol`
  margin: 0;
  padding: 0;
  [dir='ltr'] & {
    padding-left: 24px;
  }
  [dir='rtl'] & {
    padding-right: 24px;
  }

  list-style-position: outside;
  list-style-type: none;
  counter-reset: item;

  li:before {
    content: counter(item) ') ';
    counter-increment: item;
  }
`;

export const StyledLi = styled.li`
  white-space: pre-wrap;
`;

export const PrivacyWrapper = styled(Flex)`
  margin-bottom: 62px;
  flex-direction: column;

  ${mediaQueries.md} {
    flex-direction: row;
    gap: 38px;
    margin-top: 34px;
    margin-bottom: 0px;
  }
`;

export const PrivacyItemWrapper = styled(Flex)`
  flex-direction: column;
  padding-top: 152px;
  margin-bottom: -152px;

  ${mediaQueries.md} {
    padding-top: 80px;
    margin-bottom: -64px;
  }

  p:last-child {
    z-index: 1;
  }
`;

export const PrivacyItemTitleWrapper = styled(H4)`
  margin-bottom: 24px !important;
`;

export const PrivacyMenu = styled(Flex)`
  width: 181px;
  flex-direction: column;
`;

export const hovered = css`
  :hover {
    cursor: pointer;
  }
`;

export const croppedText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const croppedMultiLineText = css`
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const StyledButton = styled(Button)`
  padding: 0;
  letter-spacing: 0;
`;

export const Spinner = styled(Div)`
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotate 1s linear infinite;
`;

export const StyledContainer = styled(Container)`
  margin-bottom: 160px;
`;

export const StyledBodySmall = styled(BodySmall)<{ isMargin?: boolean }>`
  ${({ theme, isMargin }) =>
    isMargin &&
    css`
      ${theme.rtl ? 'margin-right: 16px' : 'margin-left: 16px'}!important;
    `}
`;

export const Reversed = styled(Flex)`
  ${({ theme }) => theme.rtl && `transform: scaleX(-1);`}
`;

export const Unstroked = styled(Flex)`
  path {
    stroke-width: 0 !important;
  }
`;

export const SidebarButtons = styled(Flex)`
  flex-direction: column;
  width: 100%;
  gap: 8px;
  ${mediaQueries.md} {
    gap: 16px;
  }
`;

export const StyledCaption = styled(Caption)`
  margin-bottom: -100px !important;
  ${mediaQueries.md} {
    margin-bottom: -40px !important;
  }
`;
