import { memo, forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper, StyledBodySmall } from '../../../style';
import { SUMMARY_ARTICLES_COUNT, transComponents } from '../constants';

// TODO Refactor to enable eslint checks
export const Summary = memo(
  forwardRef((_, ref) => {
    const { t } = useTranslation();

    return (
      <PrivacyItemWrapper ref={ref}>
        <PrivacyItemTitleWrapper>{t('termsOfService.summary.title')}</PrivacyItemTitleWrapper>
        {Array(SUMMARY_ARTICLES_COUNT)
          .fill(0)
          .map((__, i) => (
            <StyledBodySmall
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              mb="24px"
              isMargin={i === 2}
            >
              <Trans components={transComponents}>{t(`termsOfService.summary.article${i + 1}`)}</Trans>
            </StyledBodySmall>
          ))}
      </PrivacyItemWrapper>
    );
  }),
);
