// ! TODO: remove favouritesBreadcrumbs
export const fakeFavourites = t => [
  /* {
  /* {
    id: '6fee5c2f-6eeb-4321-b7de-4ec9c47c150d',
    headline: t('home.services.cosmetology'),
    title: 'Musnik Cosmetology',
    rating: '9.3',
    reviewsCount: '92',
    photosCount: '43',
    isActive: true,
    geometry: {
      coordinates: [31.768806421811647, 34.6281344937872],
    },
  }, */
];
