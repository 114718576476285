import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Popup, Button, BottomSheet } from '@beauty/beauty-market-ui';
import { useMediaScreen } from '../hooks';
import { RouterUrl } from '../routes/routes';

type ChangeEmailPopupProps = {
  email: string;
  handlePopupClose: () => void;
};

export const ChangeEmailPopup = ({ email, handlePopupClose }: ChangeEmailPopupProps) => {
  const { t } = useTranslation();

  const { isDesktop } = useMediaScreen('md');
  const navigate = useNavigate();

  const PopupFooterBody = (
    <Button
      size={isDesktop ? 'large' : 'medium'}
      width="100%"
      onClick={() => {
        handlePopupClose();
        navigate(RouterUrl.Homepage);
      }}
      mb={isDesktop ? '' : '8px'}
    >
      {t('button.done')}
    </Button>
  );

  return isDesktop ? (
    <Popup
      title={t('profile.checkYourBox')}
      info={t('profile.weSend') + email}
      open
      handleClose={handlePopupClose}
      FooterBody={PopupFooterBody}
    />
  ) : (
    <BottomSheet
      isOpen
      onClose={handlePopupClose}
      handleClose={handlePopupClose}
      label={t('profile.checkYourBox')}
      descriptor={t('profile.weSend') + email}
      content={PopupFooterBody}
      detent="content-height"
    />
  );
};
