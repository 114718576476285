import { useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Flex } from '@beauty/beauty-market-ui';
import { hasScroll } from '../../helpers/utils';
import { ImageBlock, InfoWrapper, LoginWrapper } from './style';

const Login = () => {
  const [isMarginTop, setMarginTop] = useState(false);
  const infoRef = useRef<HTMLElement>(null);

  const topDistance = infoRef.current?.getBoundingClientRect().top;

  window.addEventListener('resize', () => setMarginTop(hasScroll()));

  return (
    <LoginWrapper mt={['0', '0', '0', isMarginTop && topDistance && topDistance < 104 ? '24px' : '0']}>
      <InfoWrapper ref={infoRef}>
        <Flex width={['100%', '100%', '100%', '400px']} flexDirection="column">
          <Outlet />
        </Flex>
      </InfoWrapper>
      <ImageBlock />
    </LoginWrapper>
  );
};

export default Login;
