import { useTranslation } from 'react-i18next';
import { Flex, getMinWidthMediaQuery, SegmentTab, useMediaQuery } from '@beauty/beauty-market-ui';

export const TariffPlansTitle = ({ value, onChange }) => {
  const { t } = useTranslation();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);
  return (
    <Flex flexDirection="column" gap={isLarge ? '40px' : '30px'} alignItems="center">
      <SegmentTab
        tabs={[t('business.tariffPlans.filter.monthly'), t('business.tariffPlans.filter.annually')]}
        activeTab={value}
        onToggle={onChange}
      />
    </Flex>
  );
};
