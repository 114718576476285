import { useTranslation } from 'react-i18next';
import { BodySmall, Flex, Avatar } from '@beauty/beauty-market-ui';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/redux-slices/userSlice';

const SelectedSpecialist = () => {
  const { specialist } = useAppSelector(selectUser).booking;
  const { t } = useTranslation();

  return specialist ? (
    <Flex alignItems="center" gap="16px">
      <Avatar size="xs" url={specialist.avatarUrl} />
      <BodySmall>{`${specialist.name} ${specialist.surname ?? ''}`}</BodySmall>
    </Flex>
  ) : (
    <BodySmall>{t('organisation.booking.allSpecialists')}</BodySmall>
  );
};

export default SelectedSpecialist;
