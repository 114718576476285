import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { Nullable } from 'tsdef';
import {
  Button,
  Dropdown,
  Sidebar,
  Flex,
  Div,
  colors,
  Textfield,
  BodyLarge,
  Option,
  BodySmall,
  getMinWidthMediaQuery,
  useMediaQuery,
  BottomSheet,
  AlertTypes,
} from '@beauty/beauty-market-ui';
import { FormikInput } from '../../components/functional/formik/formik-input/FormikInput';
import { getSectionTitle, sendToSupport } from '../../helpers/support';
import { supportCards } from './constants';
import { CloseIconWrapper } from './style';
import {
  SupportFormFields,
  SupportFormTypes,
  initialSupportValues,
  supportFormValidationSchema,
} from './Support.definitions';

interface SupportSidebarProps {
  isOpen: boolean;
  onCancel: () => void;
  options: Option[];
  sectionId: number;
  selectedItemId: number;
  setSelectedItemId: (id: number) => void;
  alertType: Nullable<AlertTypes>;
  setAlertType: (alertType: string) => void;
}

const SupportCardSidebar = ({
  isOpen,
  onCancel,
  options,
  sectionId,
  selectedItemId,
  setSelectedItemId,
  alertType,
  setAlertType,
}: SupportSidebarProps) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);
  const [isLoading, setLoading] = useState(false);

  const onFormSubmit = async (values: SupportFormTypes) => {
    setLoading(true);

    const formData = new FormData();
    formData.append(SupportFormFields.Email, values.email);
    formData.append(SupportFormFields.Category, `${getSectionTitle(sectionId, t)}: ${values.category}`);
    formData.append(SupportFormFields.Subject, values.subject);
    formData.append(SupportFormFields.Message, values.message);
    formData.append('language', localStorage.getItem('i18nextLng')?.toUpperCase() || 'EN');
    for (const file of values.files) {
      formData.append('files', file);
    }
    const { error } = await sendToSupport(formData);
    error ? setAlertType(AlertTypes.ERROR) : setAlertType(AlertTypes.SUCCESS);

    setLoading(false);
  };

  const formikContextValue = {
    initialValues: { ...initialSupportValues, category: supportCards.find(card => +card.id === sectionId)?.item },
    validationSchema: supportFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  const FooterBody = ({ handleSubmit, isValid }) => (
    <Button
      type="submit"
      size="large"
      width="100%"
      disabled={!isValid || isLoading || alertType === AlertTypes.ERROR}
      onClick={handleSubmit}
    >
      {t('support.supportCardSlider.submitButton')}
    </Button>
  );

  const content = (
    <Flex flexDirection="column" height="100%" width="100%">
      <Form>
        <FormikInput
          mb="16px"
          design="white"
          id={SupportFormFields.Email}
          name={SupportFormFields.Email}
          placeholder={t('support.supportCardSlider.emailAddress')}
        />
        {sectionId !== 7 && (
          <Field id={SupportFormFields.Category} name={SupportFormFields.Category}>
            {({ field, form }) => (
              <Div mb="16px">
                <Dropdown
                  {...field}
                  currentOption={selectedItemId}
                  options={options}
                  onChange={value => {
                    setSelectedItemId(value);
                    form.setFieldValue(SupportFormFields.Category, options[value].item);
                  }}
                  placeholder={t('support.supportCardSlider.helpQuestion')}
                />
              </Div>
            )}
          </Field>
        )}
        <FormikInput
          mb="16px"
          design="white"
          id={SupportFormFields.Subject}
          name={SupportFormFields.Subject}
          placeholder={t('support.supportCardSlider.subject')}
          type="text"
        />
        <Field id={SupportFormFields.Message} name={SupportFormFields.Message}>
          {({ field, meta, form }) => (
            <Textfield
              {...field}
              id={SupportFormFields.Message}
              onChange={e => {
                form.setFieldValue(SupportFormFields.Message, e.target.value);
              }}
              value={field.value}
              design="white"
              mb="16px"
              rows={6}
              placeholder={t('support.supportCardSlider.description')}
              nonValid={meta.error && meta.touched}
              caption={meta.error}
            />
          )}
        </Field>
        <BodyLarge lowline color={colors.grey.dark} mb="5px">
          {t('support.supportCardSlider.attachments')}
        </BodyLarge>
        <Field id={SupportFormFields.Files} name={SupportFormFields.Files}>
          {({ field, form }) => {
            const handleUploadFiles = (files: File[]) => {
              const uploaded = [...field.value];
              // eslint-disable-next-line array-callback-return
              files.some(file => {
                if (uploaded.findIndex(item => item.name === file.name) === -1) {
                  uploaded.push(file);
                }
              });
              form.setFieldValue(SupportFormFields.Files, uploaded);
            };
            const handleEvent = e => {
              const chosenFiles = Array.prototype.slice.call(e.target.files);
              handleUploadFiles(chosenFiles);
            };

            const handleClick = () => {
              if (inputRef.current !== null) {
                inputRef.current.click();
              }
            };

            const resetFile = (name: string) => {
              form.setFieldValue(
                SupportFormFields.Files,
                field.value.filter(file => file.name !== name),
              );
            };

            const renderFileList = field.value.map((file, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Flex key={index} justifyContent="space-between">
                <BodySmall>{file.name}</BodySmall>
                <CloseIconWrapper width="20" height="20" onClick={() => resetFile(file.name)} />
              </Flex>
            ));

            return (
              <>
                <input style={{ display: 'none' }} type="file" multiple ref={inputRef} onChange={handleEvent} />
                <Button
                  design="quaternaryBlack"
                  type="button"
                  onClick={handleClick}
                  size="medium"
                  mb="50px"
                  width="100%"
                >
                  {t('support.supportCardSlider.addfileBtn')}
                </Button>
                <Div mb="50px">{renderFileList}</Div>
              </>
            );
          }}
        </Field>
      </Form>
    </Flex>
  );

  const sidebarProps = useMemo(
    () => ({
      isOpen,
      onClose: onCancel,
      handleClose: onCancel,
      label: t(`support.sections.${supportCards.find(card => +card.id === sectionId)?.item}.title`),
      descriptor: t(`support.sections.${supportCards.find(card => +card.id === sectionId)?.item}.description`),
    }),
    [sectionId],
  );

  return (
    <Formik {...formikContextValue}>
      {({ isValid, handleSubmit }) =>
        isLarge ? (
          <Sidebar {...sidebarProps} FooterBody={<FooterBody handleSubmit={handleSubmit} isValid={isValid} />}>
            {content}
          </Sidebar>
        ) : (
          <BottomSheet
            {...sidebarProps}
            detent="content-height"
            content={content}
            FooterBody={<FooterBody handleSubmit={handleSubmit} isValid={isValid} />}
          />
        )
      }
    </Formik>
  );
};

export default SupportCardSidebar;
