import styled, { css } from 'styled-components';
import { colors, mediaQueries, Flex, Link, Scroll } from '@beauty/beauty-market-ui';

export const Wrapper = styled(Flex)`
  position: relative;
  width: 100%;
  top: -12px;
  max-height: calc(100vh - 130px);
  ${mediaQueries.md} {
    position: absolute;
    width: 400px;
    max-height: calc(100vh - 152px);
    top: 48px;
    ${({ rtl }) =>
      !rtl
        ? css`
            right: -104px;
          `
        : css`
            left: -104px;
          `}
    border-radius: 16px;
    box-shadow:
      0px 0px 80px 0px rgba(0, 0, 0, 0.16),
      0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  }
  background-color: ${colors.white.standard};
  flex-direction: column;
  box-sizing: border-box;

  :hover {
    cursor: auto;
  }
`;

export const TitleWrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 24px 16px;

  ${mediaQueries.md} {
    padding: 24px;
  }
  box-sizing: border-box;
`;

export const CardsWrapper = styled(Scroll)`
  padding: 0;
  ${mediaQueries.md} {
    padding-left: 4px;
    padding-right: 4px;
  }
`;

export const FooterWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 96px;
  padding: 24px;
  background-color: ${colors.grey.light};

  ${mediaQueries.md} {
    height: 64px;
    padding: 16px 24px;
    background-color: ${colors.white.standard};
  }

  box-sizing: border-box;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const StyledLink = styled(Link)`
  gap: 4px !important;
`;
