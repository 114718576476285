import { NotifyContainer } from '@beauty/beauty-market-ui';
import { isRtl } from '../../helpers/rtl';
import { useMediaScreen } from '../../hooks';

export const AlertContainer = () => {
  const { isMobile } = useMediaScreen('md');
  const rtl = isRtl();
  const defaultAlertPosition = rtl ? 'top-left' : 'top-right';
  return <NotifyContainer position={isMobile ? 'top-center' : defaultAlertPosition} />;
};
