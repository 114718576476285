import { useTranslation } from 'react-i18next';
import { Flex, Sidebar, useMediaQuery, BottomSheet, getMinWidthMediaQuery } from '@beauty/beauty-market-ui';
import InfoIcon from '../../../../../assets/info-image.png';
import { ChangeEmailForm } from '../Forms/ChangeEmailForm';

export type ChangeEmailSidebarProps = {
  email: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setPopupOpen: (isOpen: boolean) => void;
  setNewEmail: (email: string) => void;
};

export const ChangeEmailSidebar = ({
  email,
  isOpen,
  setIsOpen,
  setPopupOpen,
  setNewEmail,
}: ChangeEmailSidebarProps) => {
  const { t } = useTranslation();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const handleClose = () => {
    setIsOpen(false);
  };

  const changeEmailForm = (
    <ChangeEmailForm
      email={email}
      setNewEmail={setNewEmail}
      onClose={handleClose}
      onPopupOpen={() => setPopupOpen(true)}
    />
  );

  const content = (
    <Flex flexDirection="column" width="100%">
      {changeEmailForm}
    </Flex>
  );

  const generalProps = {
    isOpen,
    onClose: handleClose,
    handleClose,
    label: `${t('profile.change')} ${t('profile.email')}`,
    descriptor: t('profile.newEmailDescription'),
  };

  return isLarge ? (
    <Sidebar {...generalProps}>
      <Flex flexDirection="column" alignItems="center" justifyContent="space-between" width="100%" height="100%">
        <Flex pt="94px">
          <img src={InfoIcon} alt="" />
        </Flex>
        {changeEmailForm}
      </Flex>
    </Sidebar>
  ) : (
    <BottomSheet {...generalProps} content={content} detent="content-height" />
  );
};
