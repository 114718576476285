import styled, { css } from 'styled-components';
import { Flex, colors, Div, mediaQueries, Button } from '@beauty/beauty-market-ui';
import { zIndex } from '../../../../constants';
import { iconRevert } from '../../../../helpers/rtl';

// Transform is needed for correct marker positioning.
// for details see https://github.com/google-map-react/google-map-react/issues/854#issuecomment-899021073
export const ClusterMarkerWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  background: ${colors.white.standard};
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.24),
    0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 100%;
  width: 48px;
  height: 48px;
  position: absolute;
  transform: translateZ(0) translate(-50%, -50%);

  & p {
    font-weight: 600;
  }
`;

export const MarkerWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  width: 344px;
`;

export const PointWrapper = styled(Flex)`
  position: absolute;
  transform: translateZ(0) translate(-50%, -50%);
`;

export const CardWrapper = styled(Div)<{ isDown: boolean }>`
  position: absolute;

  ${({ isDown }) => (isDown ? `top:6px;` : `bottom: 22px;`)}

  ${mediaQueries.md} {
    bottom: 44px;
    transform: translateZ(0) translate(-50%);
  }
  [dir='rtl'] & {
    direction: rtl;
  }
`;

export const ZoomButtonsWrapper = styled(Flex)`
  ${({ theme }) => `margin-${theme.rtl ? 'left' : 'right'}: 24px;`};
  flex-direction: column;
  justify-content: center;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.08),
    0px 2px 24px rgba(0, 0, 0, 0.08);
`;

export const CustomMapWrapper = styled(Div)`
  position: relative;
`;

export const GoogleMapWrapper = styled(Div)`
  position: fixed;
  left: 0;
  bottom: 1px;
  width: 100%;
  height: calc(100% - 137px);
  z-index: ${zIndex.googleMapWrapper};

  ${mediaQueries.xs} {
    height: calc(100vh - 137px);
  }
  ${mediaQueries.md} {
    bottom: -6px;
    height: calc(100vh - 144px);
  }
  direction: ltr;
`;

export const FilterResultsWrapper = styled(Div)`
  position: fixed;
  top: 158px;
  bottom: 8px;
  ${({ theme }) =>
    theme.rtl
      ? `right: 8px;
    direction: rtl`
      : `left: 8px;`};
  z-index: ${zIndex.googleFilterResultsWrapper};
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.08),
    0px 2px 24px rgba(0, 0, 0, 0.08);
  opacity: 1;
  background: ${colors.white.standard};
`;

export const FilterResultsContent = styled(Flex)`
  height: calc(100% - 50px);
  box-sizing: border-box;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  padding-right: 6px;
  margin-right: -10px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.grey.light};
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* @media screen and (max-width: 992px) {
    overflow-y: auto;
  } */
`;

export const MapPointLocationWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: ${colors.blue.light};
  border: 1px solid ${colors.blue.medium};
  border-radius: 50%;
  position: absolute;
  transform: translateZ(0) translate(-50%, -50%);
`;

export const MapPointLocationCenter = styled(Div)`
  width: 10px;
  height: 10px;
  background: ${colors.blue.medium};
  border-radius: 50%;
`;

export const ArrowWrapper = styled(Div)`
  margin-top: 40px;
  ${({ theme }) => css`
    ${theme.rtl ? 'margin-right: ' : 'margin-left: '}399px;
    ${theme.rtl && iconRevert};
  `}
`;

export const StyledButton = styled(Flex)`
  margin-top: 40px;
  button {
    ${({ theme }) => `margin-${theme.rtl ? 'left' : 'right'}: 24px;`};
  }
`;

export const NavigationButton = styled(Div)`
  margin-top: 24px;
  margin-bottom: 170px;
  ${({ theme }) => css`
  ${theme.mediaQueries.md} {
  margin-bottom: 24px;
  }
  margin-${theme.rtl ? 'left' : 'right'}: 24px;
  ${theme.rtl && iconRevert}
`};
`;

export const LabelButton = styled(Button)`
  ${({ theme }) =>
    theme.rtl &&
    css`
      direction: rtl;
      svg {
        transform: scaleX(-1);
      }
    `};
`;
