import { useTranslation } from 'react-i18next';
import { Flex, H1, H4, H6, BodySmall, BodyLarge, Link } from '@beauty/beauty-market-ui';
import Service from '../../assets/service.png';
import { SUPPORT_EMAIL, PARTNER_EMAIL } from '../../constants';
import { HeadOffice, ImgWrapper, StyledContainer, StyledFlex } from './style';

export const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer justifyContent="center">
      <Flex flexDirection="column" maxWidth="620px">
        <H1 m="0" mb={['32px', '32px', '32px', '40px']}>
          {t('aboutUs.title')}
        </H1>
        <H4 mb="24px">{t('aboutUs.section1.title')}</H4>
        <BodySmall>{t('aboutUs.section1.article11')}</BodySmall>
        <BodySmall mb="16px">{t('aboutUs.section1.article12')}</BodySmall>
        <BodySmall mb="24px">{t('aboutUs.section1.article21')}</BodySmall>
        <ImgWrapper>
          <img src={Service} alt="service" />
        </ImgWrapper>

        <BodySmall mt="24px" mb={['56px', '56px', '56px', '80px']}>
          {t('aboutUs.section1.article31')}
        </BodySmall>
        <H4 mb="24px">{t('aboutUs.section2.title')}</H4>
        <BodyLarge mb="8px">{t('aboutUs.section2.article11')}</BodyLarge>
        <Link size="md" mb={['56px', '56px', '56px', '80px']} href={`mailto:${SUPPORT_EMAIL}`}>
          {SUPPORT_EMAIL}
        </Link>
        <H4 mb="24px">{t('aboutUs.section3.title')}</H4>
        <BodyLarge mb="8px">{t('aboutUs.section3.article11')}</BodyLarge>
        <Link size="md" mb={['56px', '56px', '56px', '80px']} href={`mailto:${PARTNER_EMAIL}`}>
          {PARTNER_EMAIL}
        </Link>
        <StyledFlex>
          <HeadOffice flex="1">
            <H4 mb="32px">{t('aboutUs.headOffice')}</H4>
          </HeadOffice>

          <Flex flexDirection="column" flex="1">
            <H6 mb="24px">{t('aboutUs.address')}</H6>
            <Link size="md" href={`mailto:${SUPPORT_EMAIL}`}>
              {SUPPORT_EMAIL}
            </Link>
          </Flex>
        </StyledFlex>
      </Flex>
    </StyledContainer>
  );
};
