import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { H2, Slider, TagButton } from '@beauty/beauty-market-ui';
import { Container, Crumbs } from '../../../components';
import { useGetFavourites } from '../../../hooks/useGetFavourites';
import { FavouritesContent } from './components/FavouritesContent';
import NoFavourites from './components/NoFavourites';
import { SliderWrapper } from './style';
import { FavouritesCategories, FavouritesSlideType } from './types';

const Favourites = () => {
  const { t } = useTranslation();
  const { id: userId } = useParams();
  const { favourites } = useGetFavourites(userId);
  const [activeCategory, setActiveCategory] = useState<FavouritesCategories>(FavouritesCategories.ShowAll);

  const favouritesCategories: FavouritesSlideType[] = useMemo(() => {
    const sumFavourites = favourites.length;

    const uniqFavouriteHeadlines: string[] = favourites.reduce(
      (acc: string[], rec) => (acc.includes(rec.headline) ? acc : [...acc, rec.headline]),
      [],
    );

    const favoriteCategories = [
      { id: sumFavourites.toString(), name: t(`home.services.${FavouritesCategories.ShowAll}`), count: sumFavourites },
      ...uniqFavouriteHeadlines.map(favouritesItem => {
        const count = favourites.filter(item => item.headline === favouritesItem).length;
        return {
          id: nanoid(),
          name: favouritesItem,
          count,
        };
      }),
    ];

    return favoriteCategories.sort((a: FavouritesSlideType, b: FavouritesSlideType) => b.count - a.count);
  }, [favourites]);

  const slides = favouritesCategories.map(category => {
    const design = category.name === activeCategory ? 'active' : 'default';
    return (
      <TagButton
        onClick={() => setActiveCategory(category.name as FavouritesCategories)}
        text={category.name}
        number={category.count}
        key={category.id}
        design={design}
      />
    );
  });

  return (
    <>
      <Crumbs />
      <Container flexDirection="column" mt={['24px', '24px', '24px', 0]}>
        <H2>{t('favourites.title')}</H2>
        {favourites?.length ? (
          <>
            <SliderWrapper>
              <Slider inlineSlider slides={slides} width="100%" />
            </SliderWrapper>
            <FavouritesContent favourites={favourites} activeCategory={activeCategory} />
          </>
        ) : (
          <NoFavourites />
        )}
      </Container>
    </>
  );
};

export default Favourites;
