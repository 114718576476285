import { useTranslation } from 'react-i18next';
import { BodyLarge, Flex } from '@beauty/beauty-market-ui';
import { FormikInput, FormikPhone } from '../../../../../../components/functional';
import { ProfileFormFields } from '../../../Profile.definitions';

const ContactsForm = () => {
  const { t } = useTranslation();
  return (
    <>
      <BodyLarge large mb="16px">
        {t('profile.contacts')}
      </BodyLarge>
      <Flex flexDirection="column">
        <Flex mb="16px" width="100%" justifyContent="space-between" gap="8px">
          <FormikPhone
            codeId={ProfileFormFields.Region}
            phoneId={ProfileFormFields.Phone}
            placeholder={t('profile.phone')}
            disabled
            required
          />
        </Flex>
        <FormikInput
          design="white"
          id={ProfileFormFields.Email}
          name={ProfileFormFields.Email}
          placeholder={t('profile.email')}
          mb="16px"
          disabled
          required
        />
      </Flex>
    </>
  );
};

export { ContactsForm };
