import { useMemo, useCallback, useRef, useEffect } from 'react';
import { Element } from 'react-scroll';
import { Label, CalendarIcon, TimeslotsCard, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../constants';
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';
import { TimeslotType } from '../../../types';
import { Day, DayTimeslots } from '../style';

const getFormattedDay = (day: string, language: string) => {
  const dayFormatter = Intl.DateTimeFormat(language, { day: 'numeric' });
  const weekdayFormatter = Intl.DateTimeFormat(language, { weekday: 'short' });
  const monthFormatter = Intl.DateTimeFormat(language, { month: 'short' });

  const date = new Date(day);
  return `${weekdayFormatter.format(date)}, ${dayFormatter.format(date)} ${monthFormatter.format(date)}`;
};

interface TimeslotsDayProps {
  day: string;
  timeslots: TimeslotType[];
  visibleItems: string | undefined;
  setIsVisible: (date: string) => void;
}

const TimeslotsDay = ({ day, timeslots, visibleItems, setIsVisible }: TimeslotsDayProps) => {
  const selectedLanguage = getSelectedLanguage();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);
  const dayRef = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(dayRef, { threshold: isMobile ? 0.1 : 0.01 });

  useEffect(() => {
    document.onwheel = () => {
      if (entry && entry.isIntersecting) {
        !visibleItems?.includes(entry.target.id) && setIsVisible(entry.target.id);
      }
    };
    return () => {
      document.onwheel = null;
    };
  }, [entry]);

  const mapTimeslots = useCallback((timeslot: TimeslotType) => {
    const date = getFormattedDay(day, selectedLanguage);
    return (
      <Element key={timeslot.id} name={`timeslot-${timeslot.id}`}>
        <TimeslotsCard
          id={timeslot.id}
          data-timeslot-date={date}
          date={date}
          start={timeslot.start}
          end={timeslot.end}
        />
      </Element>
    );
  }, []);

  const dayTimeslots = useMemo(
    () => timeslots.map(mapTimeslots).filter(item => !!item),
    [timeslots, mapTimeslots, selectedLanguage],
  );

  return (
    <Day ref={dayRef} id={day}>
      <Label icon={<CalendarIcon />} size="small" padding="0">
        {getFormattedDay(day, selectedLanguage)}
      </Label>
      <DayTimeslots>{dayTimeslots}</DayTimeslots>
    </Day>
  );
};

export default TimeslotsDay;
