import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Avatar, BodyLarge, BodySmall, colors, Flex, H6, Separator, Div } from '@beauty/beauty-market-ui';
import { SpecialistEducation } from '../../../../types/specialist';
import { EducationInfo } from '../../style';

export const Education = ({ items }: { items: SpecialistEducation[] }) => {
  const { t } = useTranslation();

  if (isEmpty(items)) return null;

  return (
    <Flex flexDirection="column">
      <H6 mb="16px">{t('organisation.specialists.education')}</H6>
      {items.map((item, idx, arr) => (
        <Fragment key={item.id}>
          <Flex p="8px 0" maxWidth="100%">
            <Div flexShrink={0}>
              <Avatar url={item?.fileName?.endsWith('.pdf') ? null : item.documentUrl} size="xs" />
            </Div>
            <EducationInfo width={['80vw', '85vw', '90vw', '400px']}>
              <BodySmall truncated title={item.school}>
                {item.school}
              </BodySmall>
              <BodyLarge truncated title={item.degree}>
                {item.degree}
              </BodyLarge>
              <BodySmall color={colors.grey.dark}>{`${item.startYear} - ${item.endYear}`}</BodySmall>
            </EducationInfo>
          </Flex>
          {idx !== arr.length - 1 && <Separator />}
        </Fragment>
      ))}
    </Flex>
  );
};
