import styled from 'styled-components';
import { Div, H6, mediaQueries } from '@beauty/beauty-market-ui';

export const FooterWrapper = styled(Div)`
  width: 100%;
  padding: 56px 8px 0;
  box-sizing: border-box;

  ${mediaQueries.md} {
    padding: 72px 24px 74px;
  }
`;

export const ButtonsWrapper = styled(Div)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 375px) {
    grid-row-start: 7 !important;
  }

  @media screen and (min-width: 375px) {
    margin-top: -20px;
    grid-row-start: 4 !important;
    grid-column-start: 1 !important;
    grid-column-end: 3 !important;
  }

  @media screen and (min-width: 992px) {
    margin-top: 20px;
    grid-row-start: 2 !important;
    grid-column-start: 1 !important;
    grid-column-end: 2 !important;
  }
`;

export const FooterContainer = styled(Div)`
  max-width: 1060px;
  margin-bottom: 37px;
  display: grid;
  grid-row-gap: 48px;
  grid-column-gap: 26px;
  justify-content: space-between;
  grid-template-columns: repeat(2, 160px);
  grid-template-areas: 'company discover' 'contact partners';

  @media screen and (min-width: 320px) {
    max-width: 160px;
    grid-row-gap: 40px;
    grid-template-columns: repeat(1, 1ft);
    grid-template-areas: 'company' 'business' 'legals' 'contact' 'contacts' 'logo' 'buttons';
  }

  @media screen and (min-width: 375px) {
    max-width: 100%;
    width: 100%;
    grid-row-gap: 40px;
    grid-column-gap: 5px;
    grid-template-columns: repeat(2, 160px);
    grid-template-areas:
      'company business'
      'legals contact'
      'logo contacts'
      'buttons contacts';
  }

  @media screen and (min-width: 992px) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 64px;
    grid-row-gap: 16px;
    grid-template-columns: repeat(5, auto);
    grid-template-areas: 'logo company business legals contact' 'buttons company business legals contact';
  }
`;

export const StyledH6 = styled(H6)`
  padding-bottom: 16px;
  font-weight: 600 !important;
  @media screen and (min-width: 992px) {
    padding-bottom: 24px;
  }
`;
