import { useTranslation } from 'react-i18next';
import { TagButton, Slider } from '@beauty/beauty-market-ui';
import { isRtl } from '../../../../helpers/rtl';
import { AppointmentType } from '../../../../types/appointment';
import { confirmedList, EventStatus, modifiedStatus, statusList } from '../constants';
import { CategoriesWrapper } from '../style';
import { FullStatus } from '../types';

type EventsCategoriesProps = {
  events: AppointmentType[];
  active: FullStatus;
  onChangeStatus?: (s: FullStatus) => void;
};

const EventsCategories = ({ events, active, onChangeStatus }: EventsCategoriesProps) => {
  const { t } = useTranslation();
  const rtl = isRtl();

  const handleClick = (status: FullStatus) => {
    onChangeStatus && onChangeStatus(status);
  };

  const tagButton = (status: FullStatus, id: number) => {
    let tagNumber: number;
    if (!events.length) {
      tagNumber = 0;
    } else
      switch (status) {
        case FullStatus.ALL:
          tagNumber = events?.length || 0;
          break;
        case FullStatus.CONFIRMED:
          tagNumber = events?.filter(event => confirmedList.includes(event.status)).length || 0;
          break;
        case FullStatus.CANCELLED:
          tagNumber =
            events?.filter(event => [EventStatus.CANCELLED, EventStatus.NOSHOW].includes(event.status)).length || 0;
          break;
        case FullStatus.PAST:
          tagNumber =
            events?.filter(event => [EventStatus.PAST, EventStatus.UNCLOSED].includes(event.status)).length || 0;
          break;
        default:
          tagNumber = events?.filter(event => event.status === (status as unknown as EventStatus)).length || 0;
      }

    return tagNumber ? (
      <TagButton
        key={id}
        text={t(`appointments.events.${status.toLowerCase()}`)}
        design={status === active ? 'active' : 'default'}
        number={tagNumber}
        onClick={() => !!events.length && handleClick(modifiedStatus(status))}
      />
    ) : null;
  };

  return (
    <CategoriesWrapper>
      <Slider
        inlineSlider
        slidesGap={6}
        slides={statusList.map((item: FullStatus, id) => tagButton(item, id)).filter(tag => tag !== null)}
        rtl={rtl}
      />
    </CategoriesWrapper>
  );
};

export { EventsCategories };
