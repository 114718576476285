import styled, { css } from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../components/functional/formik/formik-input/FormikInput';
import { flexDirection } from '../../../helpers/rtl';

export const StyledFormikInput = styled(FormikInput)`
  svg {
    width: 20px !important;
    height: 12px !important;
    margin-bottom: 3px;
    fill: none !important;
  }
`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  margin-left: 0;
  margin-top: 32px;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  box-sizing: border-box;
  ${({ theme }) => css`
  ${theme.mediaQueries.md} {
    margin-${theme.rtl ? 'right' : 'left'}: 40px;
    padding: 0;
    margin-top: 40px;
  }`}
`;

export const FormWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  form {
    width: 100%;
  }
`;

export const PersonalInfoWrapper = styled(Flex)`
  height: fit-content;
  @media (max-width: 992px) {
    padding-left: 8px;
    padding-right: 8px;
    flex-direction: column;
    & > div:first-child > div:nth-child(2) {
      display: none;
    }
  }
`;

export const BackWrapper = styled(Flex)`
  align-items: center;
  gap: 4px;
  cursor: pointer;
  ${({ theme }) => css`
    flex-direction: ${flexDirection(theme.rtl)};
    ${theme.rtl &&
    css`
      svg {
        transform: scaleX(-1);
      }
    `}
  `};
`;
