import { useTranslation } from 'react-i18next';
import { Flex, H3, Link } from '@beauty/beauty-market-ui';
import { useAppSelector } from '../../../../../store/hooks';
import { selectUser } from '../../../../../store/redux-slices/userSlice';
import { Info } from './Info';
import { NotificationItemWrapper } from './style';

type AuthorisationDataProps = {
  onChangeEmailClick: () => void;
  onChangePhoneClick: () => void;
};

export const AuthorisationData = ({ onChangeEmailClick, onChangePhoneClick }: AuthorisationDataProps) => {
  const { t } = useTranslation();
  const { profile } = useAppSelector(selectUser);

  return (
    <Flex flexDirection="column" mb={['32px', '32px', '32px', '40px']}>
      <H3 mb={['8px', '8px', '8px', '60px']}>{t('profile.authorisationData')}</H3>
      <NotificationItemWrapper>
        <Info title={t('profile.e-mail')} type="change" value={profile.email} long />
        <Link size="s" onClick={onChangeEmailClick}>
          {t('profile.change')}
        </Link>
      </NotificationItemWrapper>
      <NotificationItemWrapper>
        <Info title={t('profile.phone')} type="change" value={`${profile.code} ${profile.number}`} long />
        <Link size="s" onClick={onChangePhoneClick}>
          {t('profile.change')}
        </Link>
      </NotificationItemWrapper>
    </Flex>
  );
};
