import { ReactNode } from 'react';
import { BottomSheet, Sidebar } from '@beauty/beauty-market-ui';
import { useMediaScreen } from '../../hooks';

type Props = {
  label: string;
  descriptor: string | ReactNode;
  FooterBody: ReactNode;
  children: ReactNode;
  onClose: () => void;
  isOpen: boolean;
};

export const SidebarSheet = ({ children, onClose, ...rest }: Props) => {
  const { isDesktop } = useMediaScreen('md');

  return isDesktop ? (
    <Sidebar {...rest} onClose={onClose}>
      {children}
    </Sidebar>
  ) : (
    <BottomSheet {...rest} content={children} handleClose={onClose} />
  );
};
