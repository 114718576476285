import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select } from '@beauty/beauty-market-ui';
import { SidebarSheet } from '../../../../../components';
import { getLanguageOptions } from '../../../../../constants';
import { SidebarButtons } from '../../../../../style';

export const EditLanguageSidebar = ({ isOpen, language, onSubmit, onClose }) => {
  const { t } = useTranslation();

  const languageOptions = getLanguageOptions(t);
  const [selected, setSelected] = useState<{ id: string }>(languageOptions.data[language]);

  return (
    <SidebarSheet
      isOpen={isOpen}
      label={t('profile.updateLanguage')}
      descriptor={t('profile.chooseLanguage')}
      onClose={onClose}
      FooterBody={
        <SidebarButtons>
          <Button
            size="large"
            width="100%"
            type="submit"
            onClick={() => onSubmit(selected.id)}
            disabled={selected.id === language}
          >
            {t('button.apply')}
          </Button>
          <Button size="large" width="100%" design="secondary" onClick={onClose}>
            {t('button.cancel')}
          </Button>
        </SidebarButtons>
      }
    >
      <Select
        selected={selected}
        onSelect={setSelected}
        options={Object.values(languageOptions.data)}
        placeholder={t('language.language')}
        isSeparator
      />
    </SidebarSheet>
  );
};
