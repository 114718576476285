import { NotifyStatus, NotifyPropsType } from '@beauty/beauty-market-ui';
import { AppointmentActions } from '../../types/appointment';

const successTitles = {
  [AppointmentActions.Create]: 'alert.createAppointmentSuccessTitle',
  [AppointmentActions.CreateByGuest]: 'alert.createAppointmentSuccessTitle',
  [AppointmentActions.Update]: 'alert.updateAppointmentSuccessTitle',
  [AppointmentActions.Cancel]: 'alert.cancelAppointmentSuccessTitle',
};

const errorTitles = {
  [AppointmentActions.Create]: 'alert.createAppointmentErrorTitle',
  [AppointmentActions.CreateByGuest]: 'alert.createAppointmentErrorTitle',
  [AppointmentActions.Update]: 'alert.updateAppointmentErrorTitle',
  [AppointmentActions.Cancel]: 'alert.cancelAppointmentErrorTitle',
};

const successSubtitles = {
  [AppointmentActions.Create]: 'alert.createAppointmentSuccessSubtitle',
  [AppointmentActions.CreateByGuest]: 'alert.createAppointmentSuccessSubtitle',
  [AppointmentActions.Update]: 'alert.updateAppointmentSuccessSubtitle',
  [AppointmentActions.Cancel]: 'alert.cancelAppointmentSuccessSubtitle',
};

const errorSubtitles = {
  [AppointmentActions.Create]: 'alert.createAppointmentErrorSubtitle',
  [AppointmentActions.CreateByGuest]: 'alert.createAppointmentErrorSubtitle',
  [AppointmentActions.Update]: 'alert.updateAppointmentErrorSubtitle',
  [AppointmentActions.Cancel]: 'alert.cancelAppointmentErrorSubtitle',
};

export const getAppointmentNotifyContent = (
  status: NotifyStatus,
  action: AppointmentActions,
  t,
  message?: string,
): NotifyPropsType => {
  const title = status === NotifyStatus.SUCCESS ? successTitles[action] : errorTitles[action];
  const subtitle = status === NotifyStatus.SUCCESS ? successSubtitles[action] : errorSubtitles[action];

  return {
    id: action,
    status,
    title: t(title),
    subtitle: message || t(subtitle),
  };
};
