import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Popup, Button, getMinWidthMediaQuery, useMediaQuery, BottomSheet } from '@beauty/beauty-market-ui';

import { FavouritesCategories, FavouritesOffer } from '../types';
import { FavouritesList } from './FavouritesList';

export interface FavouritesContentProps {
  favourites: FavouritesOffer[];
  activeCategory: FavouritesCategories;
}

export const FavouritesContent = ({ favourites, activeCategory }: FavouritesContentProps) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const displayedFavourites: FavouritesOffer[] = useMemo(() => {
    if (activeCategory === FavouritesCategories.ShowAll) return favourites;

    return favourites.filter(item => item.headline === activeCategory);
  }, [favourites, activeCategory]);

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const PopupFooterBody = (
    <>
      <Button
        design="secondary"
        size={isLarge ? 'large' : 'small'}
        width="100%"
        onClick={handleCloseModal}
        mb={['8px', '0']}
      >
        {t('favourites.modals.cancel')}
      </Button>
      <Button size={isLarge ? 'large' : 'small'} width="100%" onClick={() => console.log('OK pressed')}>
        {t('favourites.modals.delete')}
      </Button>
    </>
  );

  return (
    <>
      <FavouritesList favourites={displayedFavourites} setIsOpenModal={setIsOpenModal} />
      {isLarge ? (
        <Popup
          title={t('favourites.modals.specialist.title')}
          info={t('favourites.modals.specialist.body')}
          open={isOpenModal}
          handleClose={handleCloseModal}
          FooterBody={PopupFooterBody}
        />
      ) : (
        <BottomSheet
          label={t('favourites.modals.specialist.title')}
          descriptor={t('favourites.modals.specialist.body')}
          isOpen={isOpenModal}
          onClose={handleCloseModal}
          handleClose={handleCloseModal}
          content={PopupFooterBody}
          detent="content-height"
        />
      )}
    </>
  );
};
