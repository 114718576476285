import styled from 'styled-components';
import { height, width, margin, flexbox } from 'styled-system';
import { Flex } from '@beauty/beauty-market-ui';
import { ContainerProps } from './types';

const getPadding = wide => (wide ? '80px' : 'auto');

export const Wrapper = styled(Flex)<ContainerProps>`
  padding-left: ${({ wide }) => getPadding(wide)};
  padding-right: ${({ wide }) => getPadding(wide)};
  width: 1060px;
  margin: auto;
  ${width};
  ${margin};
  ${height};
  ${flexbox};

  @media (max-width: 1060px) {
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
    box-sizing: border-box;
  }
`;
