import { forwardRef, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import _ from 'lodash';
import { Button, Div, BodyLarge, useNotify, NotifyStatus } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../../../components/functional/formik/formik-input/FormikInput';
import { InvalidVariants } from '../../../../../constants';
import { getUserNotifyContent } from '../../../../../helpers/notifyContent/user';
import { getProfile, updatePhone, updatePhoneSendSms } from '../../../../../helpers/profile';
import { getInvalidType } from '../../../../../helpers/utils';
import { useGetSecondsLeft } from '../../../../../hooks/useGetSecondsLeft';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectUser, updateProfile, updateSmsCodeTime } from '../../../../../store/redux-slices/userSlice';
import { UserActions } from '../../../../../types/user';
import {
  initialSmsCodeValues,
  LoginFormSmsCodeFields,
  LoginSmsCodeForm,
  loginSMSCodeFormValidationSchema,
} from '../../../../Login/Login.definitions';
import { FormWrapper } from '../../style';

type SendSmsCodeFormProps = {
  newCode: string;
  newNumber: string;
  onClose: () => void;
  setSubmitActive: (isActive: boolean) => void;
};

export const SendSmsCodeForm = memo(
  forwardRef((props: SendSmsCodeFormProps, ref) => {
    const { newCode, newNumber, onClose, setSubmitActive } = props;
    const { t } = useTranslation();
    const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);

    const { profile, user } = useAppSelector(selectUser);
    const secondsLeft = useGetSecondsLeft();
    const dispatch = useAppDispatch();
    const notify = useNotify();

    const handleChangePhone = async (data: LoginSmsCodeForm) => {
      const payload = {
        code: profile.code,
        number: profile.number,
        newCode,
        newNumber,
        otp: data[LoginFormSmsCodeFields.SMSCode],
      };

      const response = await updatePhoneSendSms(payload);
      if (response.success) {
        const toRedux = await getProfile(user.userId);
        toRedux && dispatch(updateProfile(toRedux));
        onClose();
        setInvalidType(null);
        notify(getUserNotifyContent(NotifyStatus.SUCCESS, UserActions.UpdatePhone, t));
      } else setInvalidType(getInvalidType(response.statusCode, 'codeVerification'));
    };

    const handleResendClick = async () => {
      setInvalidType(null);
      const phoneVer = { code: newCode, number: newNumber };
      const response = await updatePhone(phoneVer);
      response && dispatch(updateSmsCodeTime(new Date().getTime()));
    };

    const formikContextValue = {
      initialValues: initialSmsCodeValues,
      validationSchema: loginSMSCodeFormValidationSchema(t),
      onSubmit: handleChangePhone,
      validateOnMount: false,
    };

    return (
      <Formik innerRef={ref as (instance: FormikProps<LoginSmsCodeForm> | null) => void} {...formikContextValue}>
        {({ isValid, initialValues, values }) => {
          setSubmitActive(isValid && !_.isEqual(initialValues, values)); // TODO Refactor set state
          return (
            <FormWrapper>
              <Form>
                <BodyLarge mb="16px">{t('profile.enterSmsCode')}</BodyLarge>
                <Div width="100%">
                  <FormikInput
                    width="100%"
                    id={LoginFormSmsCodeFields.SMSCode}
                    name={LoginFormSmsCodeFields.SMSCode}
                    placeholder={t('registration.smsCode')}
                    invalidType={invalidType}
                    onInput={() => setInvalidType(null)}
                    focused
                  />
                </Div>
              </Form>
              <Button
                disabled={secondsLeft}
                design="quaternary"
                mt="24px"
                width="100%"
                size="large"
                onClick={handleResendClick}
              >
                {`${t(`registration.resendCode`)}${secondsLeft ? `${` 00:`}${`0${secondsLeft}`.slice(-2)}` : ``}`}
              </Button>
            </FormWrapper>
          );
        }}
      </Formik>
    );
  }),
);
