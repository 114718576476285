import { useTranslation } from 'react-i18next';
import { Div, H2, BodySmall, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';

const SentEmailNotification = () => {
  const { t } = useTranslation();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  return (
    <Div textAlign={isDesktop ? 'center' : 'start'}>
      <H2 mb="16px">{t('signUp.checkInbox')}</H2>
      <BodySmall>{t('registration.resetInfo')}</BodySmall>
    </Div>
  );
};

export default SentEmailNotification;
