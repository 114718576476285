import styled from 'styled-components';
import { Flex, Promo } from '@beauty/beauty-market-ui';

export const NoFavouritesWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  width: 233px;
  height: 70vh;
  margin: auto;
  align-items: center;
  text-align: center;
`;

export const PromoStyled = styled(Promo)`
  width: 334px !important;
`;

export const BreadcrumbsWrapper = styled(Flex)`
  margin-bottom: 37px;

  @media screen and (min-width: 768px) {
    margin-bottom: 46px;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 69px;
  }
`;

export const SliderWrapper = styled(Flex)`
  margin-top: 32px;
  align-self: flex-start;
  justify-self: start;

  @media screen and (min-width: 768px) {
    margin-top: 36px;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 40px;
  }
`;

export const FavouritesListWrapper = styled(Flex)`
  margin-top: 60px;

  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
  }
`;
