import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Flex, Button, AlertTypes } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../components/functional/formik/formik-input/FormikInput';
import { FormikTextfield } from '../../components/functional/formik/formik-textfield/FormikTextfield';
import { sendToSupport } from '../../helpers/support';
import { SendForm, initialSendFormValues, sendFormValidationSchema } from './ContactUs.definitions';

type SendMessageFormProps = {
  setAlertType: (alertType: string) => void;
};

export const SendMessageForm = ({ setAlertType }: SendMessageFormProps) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const onFormSubmit = async (values: SendForm) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('email', values.email);
    formData.append('category', t('contactUs.category'));
    formData.append('subject', `${t('support.supportCardSlider.subject')}: ${t('support.sections.other.title')}`);
    formData.append('message', values.message);
    formData.append('language', localStorage.getItem('i18nextLng')?.toUpperCase() || 'EN');
    try {
      const { error } = await sendToSupport(formData);
      error ? setAlertType(AlertTypes.ERROR) : setAlertType(AlertTypes.SUCCESS);
    } catch (error) {
      setAlertType(AlertTypes.ERROR);
    }
    setLoading(false);
  };

  const formikContextValue = {
    initialValues: initialSendFormValues,
    validationSchema: sendFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  return (
    <Flex width="100%" flexDirection="column">
      <Formik {...formikContextValue}>
        {({ isValid, values }) => (
          <Form>
            <Flex width="100%" flexDirection="column">
              <FormikInput mb="24px" design="grey" id="email" name="email" placeholder={t('contactUs.email')} />
              <FormikTextfield
                design="grey"
                id="message"
                name="message"
                placeholder={t('contactUs.message')}
                height="140px"
              />
            </Flex>
            <Button
              disabled={!isValid || !values.email || !values.message || isLoading}
              type="submit"
              design="primary"
              mt="24px"
              size="large"
            >
              {t('contactUs.send')}
            </Button>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};
