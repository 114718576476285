import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { getSelectedLanguage } from '../constants';
import { fetchServiceDetails } from '../helpers/organisation';
import { getTranslation } from '../helpers/utils';
import { getFormattedCurrency } from '../page/Organisation/helpers';
import { useAppSelector } from '../store/hooks';
import { selectOrganisation } from '../store/redux-slices/organisationSlice';
import { ServiceType } from '../types';

export const useGetServiceInfo = (serviceId?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serviceInfo, setServiceInfo] = useState<Nullable<ServiceType>>(null);
  const language = getSelectedLanguage();
  const { t } = useTranslation();
  const { organisation } = useAppSelector(selectOrganisation);

  useEffect(() => {
    let isServiceInfoLoading = true;

    const fetchServiceInfo = async () => {
      setIsLoading(true);
      const currentService = serviceId && (await fetchServiceDetails(serviceId));
      const { id, price, duration, orgSpecialist, headOrgService } = currentService;
      const { title, descr, category, photo } = headOrgService;

      if (isServiceInfoLoading) {
        setServiceInfo({
          id,
          title,
          label: getTranslation(category?.title, language),
          description: descr,
          // TODO: remove price choise after BE fixed
          price:
            price !== null && category.parentId !== 'd3147656-b459-4c4f-9175-9355f6e1b454'
              ? getFormattedCurrency(price, organisation?.headOrganization.currency, language)
              : t('organisation.booking.nullPrice'),
          categoryId: category?.id,
          photos: photo?.map(item => item.url),
          duration,
          orgSpecialist,
          category: category.title,
        });
        setIsLoading(false);
      }
    };

    serviceId && fetchServiceInfo();

    return () => {
      isServiceInfoLoading = false;
    };
  }, [serviceId, t]);

  return {
    isLoading,
    serviceInfo,
  };
};
