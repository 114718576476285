import { forwardRef, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Flex, H6, ListRegionCode } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../../components/functional/formik/formik-input/FormikInput';
import { textAlign } from '../../../../helpers/rtl';
import { getListRegionCode } from '../../../../helpers/utils';
import { useAppSelector } from '../../../../store/hooks';
import { selectUser } from '../../../../store/redux-slices/userSlice';
import { PhoneWrapper, StyledFlex } from '../../style';
import {
  initialValues,
  PersonalDataFormFields,
  PersonalDataFormTypes,
  personalDataFormValidationSchema,
} from './PersonalData.definitions';

interface PersonalDataTypes {
  onFormSubmit: (data: PersonalDataFormTypes) => void;
  setIsValid: (value: boolean) => void;
}

const PersonalData = memo(
  forwardRef(({ onFormSubmit, setIsValid }: PersonalDataTypes, ref) => {
    const { t } = useTranslation();
    const { orgId } = useParams();
    const { isLogin, user } = useAppSelector(selectUser);

    const formikContextValue = {
      initialValues: initialValues(isLogin ? user : null, orgId),
      validationSchema: personalDataFormValidationSchema(t),
      onSubmit: onFormSubmit,
      validateOnMount: false,
    };

    return (
      <>
        <H6 mt="40px" mb="16px">
          {t('organisation.booking.infoAboutYou')}
        </H6>
        <Formik {...formikContextValue} innerRef={ref as (instance: FormikProps<PersonalDataFormTypes> | null) => void}>
          {({ dirty, errors, setFieldValue, values }) => {
            useEffect(() => {
              (dirty || isLogin) && setIsValid(Object.keys(errors).length === 0);
            }, [dirty, isLogin, errors]);

            return (
              <Form>
                <Flex flexDirection="column" gap="16px">
                  <FormikInput
                    design="white"
                    id={PersonalDataFormFields.Name}
                    name={PersonalDataFormFields.Name}
                    placeholder={t('profile.name')}
                    disabled={isLogin ? !!user.name : false}
                    required
                  />
                  <FormikInput
                    design="white"
                    id={PersonalDataFormFields.Surname}
                    name={PersonalDataFormFields.Surname}
                    placeholder={t('profile.surname')}
                    disabled={isLogin ? !!user.surname : false}
                    required
                  />
                  <FormikInput
                    design="white"
                    id={PersonalDataFormFields.IdNumber}
                    name={PersonalDataFormFields.IdNumber}
                    placeholder={t('profile.idNumber')}
                    disabled={isLogin ? !!user.idNumber : false}
                  />
                  <FormikInput
                    design="white"
                    id={PersonalDataFormFields.Email}
                    name={PersonalDataFormFields.Email}
                    placeholder={t('form.email')}
                    mb="16px"
                    disabled={isLogin ? !!user.email : false}
                    required
                  />
                </Flex>

                <StyledFlex>
                  <Field
                    component={ListRegionCode}
                    currentRegionCode={values[PersonalDataFormFields.Region]}
                    handleSelect={(_code: string) => setFieldValue(PersonalDataFormFields.Region, _code)}
                    id={PersonalDataFormFields.Region}
                    name={PersonalDataFormFields.Region}
                    placeholder={t('form.region')}
                    options={getListRegionCode()}
                    design="white"
                    value={values[PersonalDataFormFields.Region]}
                    disabled={isLogin ? !!user.code : false}
                    required
                  />
                  <PhoneWrapper>
                    <FormikInput
                      design="white"
                      id={PersonalDataFormFields.Phone}
                      name={PersonalDataFormFields.Phone}
                      placeholder={t('form.phone')}
                      disabled={isLogin ? !!user.number : false}
                      required
                    />
                  </PhoneWrapper>
                </StyledFlex>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }),
);

export default PersonalData;
