import styled, { css } from 'styled-components';
import {
  Flex,
  Span,
  BodySmallBasicStyles,
  mediaQueries,
  NavigationPointerIcon,
  Scroll,
} from '@beauty/beauty-market-ui';
import { zIndex } from '../../../../constants';
import { hovered } from '../../../../style';

export const SearchDropdownWrapper = styled(Flex)<{ isExtended: boolean }>`
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white.standard};
  max-width: 100%;

  padding: 18px 16px;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;

  ${mediaQueries.md} {
    box-shadow:
      0px 0px 2px rgba(0, 0, 0, 0.08),
      0px 2px 24px rgba(0, 0, 0, 0.08);
    ${({ isExtended }) => css`
      max-width: ${isExtended ? `620px;` : `280px;`};
    `}
  }
`;

export const StyledIcon = styled(NavigationPointerIcon)<{ disabled: boolean }>`
  position: relative;
  width: 18px;
  height: 18px;
  left: 2px;
  ${({ disabled, theme }) => !disabled && `fill: ${theme.colors.blue.standard}!important;`}
  path {
    stroke: ${({ theme }) => theme.colors.white.standard};
  }
  ${({ theme }) => theme.rtl && 'transform: scaleX(-1)'};
`;

export const StyledScroll = styled(Scroll)`
  max-height: 320px !important;
`;

export const DropdownWrapper = styled.ul`
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding: 0px;

  list-style-type: none;
  cursor: pointer;
`;

export const DropListWrapper = styled(Flex)`
  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const ItemWrapper = styled.li`
  height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  box-sizing: border-box;
  :hover {
    background: ${({ theme }) => theme.colors.grey.light};
  }
  :last-child {
    margin-bottom: 16px;
  }
`;

export const OptionsItem = styled(Span)`
  ${BodySmallBasicStyles(false, false)}
`;

export const StyledFlex = styled(Flex)`
  max-width: 572px;
  width: 100%;
  height: 82px;
  padding: 12px 0px 20px;
  box-sizing: border-box;
  gap: 16px;

  * {
    ${hovered}
  }
  ${hovered}
`;

export const DropWrapper = styled(Flex)<{ isRight: boolean }>`
  z-index: ${zIndex.searchDropdownWrapper};
  margin-top: 4px;
  position: absolute;
  width: 100%;
  top: 48px;
  ${({ isRight }) => css`
    justify-content: ${isRight ? 'end' : 'start'};
    width: ${isRight ? '' : '100%'};
  `}
`;
