import styled, { css } from 'styled-components';
import {
  TagButton,
  Flex,
  EventCard,
  Button,
  BottomSheet,
  mediaQueries,
  colors,
  Separator,
} from '@beauty/beauty-market-ui';

export const margin = (pixel, rtl) => `
  margin-${rtl ? 'right' : 'left'}:${pixel}px!important;
`;

export const StyledTagButton = styled(TagButton)`
  margin-right: 12px;
  :last-child {
    margin-right: 0;
  }
  color: ${props => props.theme.colors.black.standard};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  ${mediaQueries.md} {
    width: 200px;
  }
`;

export const StyledFlex = styled(Flex)`
  gap: 8px;
  flex-direction: column;
`;

export const StyledCard = styled(EventCard)`
  margin-bottom: 16px;
  margin-left: 56px;
`;

export const ContentWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 3;
`;

export const RatingWrapper = styled(Flex)`
  flex-direction: column;
  flex-wrap: wrap;
  gap: 45px;
  margin: 25px 0 45px 0;

  @media screen and (max-width: 1000px) {
    flex-direction: row;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    margin: 10px 0 45px 0;
  }
`;

export const BodyButton = styled(Button)`
  width: 100%;
`;

export const BodyWrapper = styled(Flex)`
  width: 100%;
  position: relative;
  flex-direction: column;
  gap: 36px;
  ${mediaQueries.md} {
    flex-direction: row;
  }
`;

export const CardWrapper = styled(Flex)`
  border-bottom: 1px solid ${colors.grey.light};
  margin-left: 0;
  ${mediaQueries.md} {
    border: none;
    ${({ theme }) => margin(64, theme.rtl)}
  }
`;

export const AppointmentInfoWrapper = styled(Flex)`
  & > div:first-child {
    padding-top: 4px;
    margin-left: 16px;
  }
  span {
    cursor: default;
  }
`;

export const StyledBottomSheet = styled(BottomSheet)<{ isCancel: boolean }>`
  ${({ isCancel }) =>
    isCancel
      ? css`
          & > div:first-of-type > div:nth-child(2) {
            border-bottom: none;
            padding-bottom: 4px;
          }
          div:nth-child(3) > div:first-child {
            padding-bottom: 0;
          }
        `
      : css`
          & > div:first-child {
            padding-bottom: 16px !important;
          }
        `}
`;

export const ScrollWrapper = styled(Flex)`
  width: 100%;
  max-width: 100%;
  height: 100%;
  ${mediaQueries.md} {
    height: 57vh;
    max-width: 300px;
  }
`;

export const CardsWrapper = styled(Flex)`
  height: 100%;
  flex-direction: row;
  ${mediaQueries.md} {
    flex-direction: column;
  }
  gap: 8px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  & > div {
    border: 1px solid transparent;
  }
`;

export const EventCardWrapper = styled(Flex)`
  flex-direction: column;
  margin-top: -8px;
  margin-left: ${({ theme }) => (theme.rtl ? '0' : '8px')};
  margin-right: ${({ theme }) => (theme.rtl ? '8px' : '0')};
`;

export const CategoriesWrapper = styled(Flex)`
  margin-bottom: 40px;
  .swiper {
    ${({ theme }) => css`
      margin-left: ${theme.rtl ? 'auto' : '0'};
      margin-right: ${theme.rtl ? '0' : 'auto'};
    `};
    & + div {
      display: none;
    }
  }
  .swiper-slide:first-of-type {
    ${({ theme }) => `${theme.rtl ? 'margin-right: 0' : 'margin-left: 0'}!important;`};
  }
  .swiper-slide * {
    flex-direction: row;
  }
`;

export const PriceWrapper = styled(Flex)`
  height: 57px;
  justify-content: space-between;
  align-items: center;
  ${mediaQueries.md} {
    ${({ theme }) => margin(64, theme.rtl)}
  }
`;

export const ButtonsWrapper = styled(Flex)`
  margin-top: 38px;
  ${mediaQueries.md} {
    ${({ theme }) => margin(64, theme.rtl)}
  }
`;

export const StyledSeparator = styled(Separator)`
  margin-bottom: 32px;
  ${mediaQueries.md} {
    margin-bottom: 27px;
    ${({ theme }) => margin(64, theme.rtl)}
  }
`;
